import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import BreadcrumbBox from '../../components/common/Breadcrumb'
import { Styles } from '../../components/common/styles/header'
import Footer from '../../components/Footer'
import { Styless } from './style/news'
import { Container, Row, Col, Card } from 'react-bootstrap'
import SearchResearcher from '../../components/SearchResearcher'
import { useParams, Link } from 'react-router-dom'

const NewsListTemplate = () => {
  const params = useParams()
  const [news, setNews] = useState({})
  const [Data, setData] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    window.scrollTo(0, 0)
    try {
      fetch(`https://api.shooliniuniversity.com/NewsAPI`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        }),
        body: JSON.stringify({ auth: 'shoolini@999' }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.success?.data?.length > 0) {
            setNews(res.success?.data)
            setLoading(false)
          }
          document.title = `News | Shoolini University`
        })

      return () => {
        document.title = `Shoolini University`
      }
    } catch (error) {
      console.error(error)
    }
  }, [])

  const dateFormat = (date) => {
    var options = { year: 'numeric', month: 'long', day: 'numeric' }
    var today = new Date(date)
    return today.toLocaleDateString('en-US', options)
  }

  return (
    <>
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper visionmission-page">
          {/* Header 2 */}
          <Header />

          {/* Breadcroumb */}
          <BreadcrumbBox title="News" />
          <Styless>
            <section className="admidsionProcess my-5 pt-3 ">
              <div className="about__area about__area_one p-relative pt---100 pb---120 mb-5">
                <div className="container">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="section-title olList titleAdjust tables">
                        {/* <h4>About Us</h4> */}

                        {loading ? (
                          <div className="d-flex justify-content-center">
                            <div className="spinner-border" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                            <span>Loading...</span>
                          </div>
                        ) : (
                          <>
                            {news.length > 0 && (
                              <Row>
                                {news.map((item, index) => {
                                  return (
                                    <Col md={6} sm={12} className="mb-3 newsSection">
                                      <Link to={item.slug}>
                                        <Card>
                                          <div className="newsImg">
                                          <Card.Img
                                            variant="top"
                                            src={`https://api.shooliniuniversity.com/media/${item.avatar}`}
                                          />
                                           </div>
                                          <Card.Body>
                                            <Card.Title>
                                              {item.title}
                                            </Card.Title>
                                            <Card.Subtitle>
                                              {dateFormat(item.updated_at)}
                                            </Card.Subtitle>
                                            <Card.Text>
                                              {item.excerpt}
                                            </Card.Text>
                                          </Card.Body>
                                        </Card>
                                      </Link>
                                    </Col>
                                  )
                                })}
                              </Row>
                            )}
                          </>
                        )}

                        {/* <p
                          dangerouslySetInnerHTML={{
                            __html: Data?.content_below_boxes,
                          }}
                        ></p> */}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="sidebar-content nnn">
                        <SearchResearcher />
                      </div>

                      <div className="sideBarNew">
                        <div className="enqForm">
                          <div
                            className="npf_wgts"
                            data-height="500px"
                            data-w="7d1b402b547afa4d6a10968170a856b6"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Styless>

          <Footer />
        </div>
      </Styles>
    </>
  )
}

export default NewsListTemplate
