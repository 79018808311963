import React from 'react'

var s=document.createElement("script");  s.type="text/javascript"; s.async=true; s.src="https://widgets.nopaperforms.com/emwgts.js";  document.body.appendChild(s);


const AdmissionFormSidebar = () => {
  return (
    <>  
 <div className="sideBarNew mt-3">
                    <div className="enqForm">
                      <div className="npf_wgts" data-height="500px" data-w="7d1b402b547afa4d6a10968170a856b6"></div>
                    </div>
                  </div>

    </>
  )
} 

export default AdmissionFormSidebar