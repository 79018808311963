import React from 'react'

const Result = () => {
    return (
        <>
            
        </>
    )
}

export default Result
