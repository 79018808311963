import React from 'react'
import { hydrate, render } from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter } from 'react-router-dom'

const rootElement = document.getElementById('root')

const AppR = (
  <BrowserRouter>
    <App />
  </BrowserRouter>
)

if (rootElement.hasChildNodes()) {
  hydrate(AppR, rootElement)
} else {
  render(AppR, rootElement)
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
