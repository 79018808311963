import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import { Styles } from './style/programmes'
import Aos from 'aos'
import 'aos/dist/aos.css'
import FindPrograme from '../../components/FindPrograme'
import { useLocation } from 'react-router-dom'
import Slider from 'react-slick'

function useQuery() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}
const BASE_URI = 'https://api.shooliniuniversity.com'
const Programmes = () => {
  var school_result = []

  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])

  const [ddata, setPgata] = useState([])
  // let query = useQuery();

  const fetchProgramData = (id) => {
    // console.log(id)
    return fetch('https://api.shooliniuniversity.com/GetHprogramAPI', {
      method: 'post',
      headers: new Headers({
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify({
        auth: 'shoolini@999',
        url: id,
      }),
    }).then((response) => response.json())
  }

  useEffect(() => {
    fetch('https://api.shooliniuniversity.com/HomeprogramAPI', {
      method: 'post',
      headers: new Headers({
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify({ auth: 'shoolini@999' }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res?.success) {
          let requests = res?.success?.map((name) =>
            fetchProgramData(name.link_url)
          )
          Promise.all(requests).then((responses) => {
            console.log(responses)
            let data = responses.map((i) => i.success?.[0])
            for (let index = 0; index < res?.success.length; index++) {
              if (data[index]?.courses) {
                res.success[index].courses = data[index]?.courses.slice(0, 3)
              }
            }
            setPgata(res)
          })
        }
      })
  }, [])

  school_result = ddata?.success
  // console.log(school_result)

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
      },
      {
        breakpoint: 10000, // a unrealistically big number to cover up greatest screen resolution
        settings: 'unslick',
      },
    ],
  }

  return (
    <>
      <Styles>
        {/* Faq & Event */}
        <section className="programme-area">
          <Container>
            <Row>
              <Col md="12">
                <div
                  data-aos="fade-right"
                  className="sec-title mb-4 pb-md-4 pt-md-4"
                >
                  <h2>SCHOOLS and Programs </h2>
                </div>
              </Col>
              <Col lg="10" md="12">
                <Row>
                  <Slider {...settings}>
                    {school_result?.map((data, index) => {
                      return (
                        <Fragment key={index}>
                          <div className="col-md-4" md="4">
                            <div className="programme_card">
                              {
                                <div className="school_name">
                                  <span>
                                    {' '}
                                    {data?.link_url
                                      .split('-')
                                      .join(' ')
                                      .toUpperCase()}
                                  </span>
                                </div>
                              }

                              <div className="programme_detail">
                                <h3> {data?.title} </h3>
                                {/* <h5> BTech | MTech | PhD </h5> */}
                                <ul className="programme_list">
                                  {data.courses &&
                                    data.courses.map((i, idx) => {
                                      return (
                                        <li key={idx}>
                                          {' '}
                                          <Link
                                            to={`/${data?.link_url}`}
                                            state={{ school: true }}
                                          >
                                            {' '}
                                            {i.title}{' '}
                                          </Link>{' '}
                                        </li>
                                      )
                                    })}

                                  {/* <li> AI /Machine Learning </li> */}
                                  {/* <li> Big Data Analytics </li> */}
                                  {/* <li> Cloud Computing </li> */}
                                </ul>
                                <div className="featured_img">
                                  {data?.image_url.indexOf('storage') === 1 ? (
                                    <img
                                      className="img-fluid"
                                      src={`https://api.shooliniuniversity.com${data?.image_url}`}
                                    />
                                  ) : (
                                    <img
                                      className="img-fluid"
                                      src={data?.image_url}
                                    />
                                  )}

                                  <Link
                                    to={`/${data?.link_url}`}
                                    state={{ school: true }}
                                  >
                                    {' '}
                                    See All Programs
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      )
                    })}
                  </Slider>
                </Row>
              </Col>

              <Col md="12">
                <FindPrograme />
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>
    </>
  )
}

export default Programmes
