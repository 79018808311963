import React, { useEffect, useState } from 'react'
import CampusApiTemplate from './Pages/Admission/Admission-Process/CampusApiTemplate'
import Academics from './components/Academics'
import AcademicsSub from './components/Academics-sub'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import Faculty from './components/Faculty'
import AdmissionProcess from './Pages/Admission/Admission-Process/AdmissionProcess'
import ProgrameDetails from './Pages/Programmes/ProgrameDetails'
import FacultyAll from './Pages/FacultyAll/FacultyAll'
import SchoolDetail from './Pages/School-Programe/SchoolDetail'
import CampusTemplate from './Pages/Campus/Community-Programes/CampusTemplate'


let mainTemp = null

const DynamicTemplanting = () => {
  const { pathname, state } = useLocation()
  const params = useParams()
  const navigate = useNavigate()
  const [isloading, setIsLoading] = useState(true)
  // const [temp, setTemp] = useState(null)
  const [id, setId] = useState(null)

  console.log(state)


  useEffect(() => {
    try {
      fetch('https://api.shooliniuniversity.com/GetTemplateNameAPI', {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
          auth: 'shoolini@999',
          url: params.slug,
        }),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res) {
            // Template = temps[res.success[0].templatename]
            mainTemp = res.success[0].templatename
            // console.log(Template)
            setId(res.success[0].id)
            setIsLoading(false)
            // if (Template) {
            //   React.createElement(Template)
            // }
          }
        })
        .catch((error) => {
          console.log(error)
          navigate('/404')
          setIsLoading(false)
        })
    } catch (error) {
      console.log(error)
    }
  }, [pathname])

  function renderComponnet(key, id) {
    if (state && state.school) {
      return <SchoolDetail id={id} />
    }
    switch (key) {
      case 'generic':
        return <AdmissionProcess id={id} />
        break
      case 'stream2':
        return <Academics id={id} />
        break
      case 'stream':
        return <AcademicsSub id={id} />
        break

      case 'homepage':
        return <AdmissionProcess id={id} />
        break

      case 'course':
        return <ProgrameDetails slug={params?.slug} />
        break

      case 'faqs':
        return <AdmissionProcess id={id} />
        break

      case 'faculty-all':
        return <FacultyAll id={id} />
        break

      case 'placements':
        return <AdmissionProcess id={id} />
        break

      case 'admissions':
        return <CampusApiTemplate id={id} />
        break

      case 'courses':
        return <AdmissionProcess id={id} />
        break

      case 'photo-gallery':
        return <AdmissionProcess id={id} />
        break

      case 'video-gallery':
        return <AdmissionProcess id={id} />
        break

      case 'apply-now':
        return <AdmissionProcess id={id} />
        break

      case 'campus-life':
        return <CampusTemplate id={id} />
        break

      case 'contact':
        return <AdmissionProcess id={id} />
        break

      case 'leadership':
        return <AdmissionProcess id={id} />
        break

      case 'search-results':
        return <AdmissionProcess id={id} />
        break

      case 'fee':
        return <AdmissionProcess id={id} />
        break

      default:
        break
    }
  }

  return (
    <>
      { (
        renderComponnet(mainTemp, id)
      )}
    </>
  )
}

export default DynamicTemplanting
