import styled from "styled-components";
export const Style = styled.div`

.welcome-area {
    background: url(/assets/landing-pages/blog-5.jpg) ;
    height: 800px;
    z-index: 1;
    background-size: cover;
    background-repeat: no-repeat;
}
h1 {
    font-size: 4em;
    font-weight: 600;
    line-height: 1.2;
}

.bg-overlay:after{
    background: linear-gradient(-47deg, #8731e817 0%, #4528DC 100%);
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}
.welcome-intro {
    position: relative;
    z-index: 99;
}
a#blink
{
    color:#fff;
}
.button {
    background-color: #1c87c9;
    -webkit-border-radius: 60px;
    border-radius: 60px;
    border: none;
    color: #eeeeee;
    cursor: pointer;
    display: inline-block;
    font-family: sans-serif;
    font-size: 20px;
    padding: 12px 18px;
    text-align: center;
    text-decoration: none;
  }
  @keyframes glowing {
    0% {
      background-color: #2ba805;
      box-shadow: 0 0 5px #2ba805;
    }
    50% {
      background-color: #49e819;
      box-shadow: 0 0 20px #49e819;
    }
    100% {
      background-color: #2ba805;
      box-shadow: 0 0 5px #2ba805;
    }
  }
  .button {
    animation: glowing 1300ms infinite;
  }



@media screen and (max-width:767px)
{
h1 {
    font-size: 2em !important;
    line-height: 1.4;
}
h2 {
    font-size: 24px !important;
    line-height: 1.4;
}
.welcome-area {
    height: 100% !important;
    padding-top: 200px;
    background-size: cover;
    background-position: center;
}
img.navbar-brand-regular {
    height: auto;
    max-width: 70%;
}
}





body {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: #565656;
}

.main {
    background: #fff;
}

section,
.section {
    position: relative;
}

.container {
    width: 100%;
    margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
    color: #222;
    margin-bottom: 0;
}

h1 {
    font-size: 4em;
    font-weight: 600;
    line-height: 1.2;
}

h2 {
    font-size: 40px;
    line-height: 1.3;
}

h3 {
    font-size: 20px;
    line-height: 1.2;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 16px;
}

h6 {
    font-size: 14px;
    font-weight: 400;
}

p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #565656;
    margin-bottom: 0;
}

a {
    color: #444;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

a:hover,
a:focus {
    color: #e83e8c;
}

a,
a:hover,
a:focus,
.btn:focus {
    text-decoration: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.text-body > a {
    text-decoration: underline;
    color: #e83e8c;
}

ol,
ul {
    margin: 0;
    padding: 0;
}

ol li,
ul li {
    list-style: none;
}

img {
    height: auto;
    max-width: 100%;
}

/* ******************************
:: 4.0 COMMON CSS
****************************** */
.fw-3 {
    font-weight: 300;
}

.fw-4 {
    font-weight: 400;
}

.fw-5 {
    font-weight: 500;
}

.fw-6 {
    font-weight: 600;
}

.fw-7 {
    font-weight: 700;
}

.op-5 {
    opacity: 0.5;
}

.avatar-sm {
    height: 3rem;
    width: 3rem;
}

.avatar-md {
    height: 4rem;
    width: 4rem;
}

.avatar-lg {
    height: 5rem;
    width: 5rem;
}

.text-underlined,
.text-underlined:hover,
.text-underlined:focus {
    text-decoration: underline;
}

.text-primary {
    color: #7c4fe0 !important;
}

.text-secondary {
    color: #666 !important;
}

.featured-icon > span::before {
    font-size: 4.25rem;
    line-height: 1;
    color: #777;
    margin: 0;
}

.radius-100 {
    border-radius: 100px !important;
}

.h-100vh {
    height: 100vh;
}

.owl-carousel .owl-item img {
    width: auto;
}

.owl-prev:focus,
.owl-next:focus {
    outline: none;
}

/*SECTION HEADING*/
.section-heading {
    margin-bottom: 80px;
}

.section-heading > span {
    color: #222;
}

.section-heading > h2 {
    position: relative;
}

.section-heading > h2::after {
    position: absolute;
    content: '';
    height: 2px;
    width: 70px;
    background-color: #7c4fe0;
    bottom: -15px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.homepage-5 .features-area .section-heading > h2::after,
.contact-page .contact-area .section-heading > h2::after {
    left: 0;
    transform: inherit;
}

/*SECTION PADDING*/
.pt_0 {
    padding-top: 0;
}

.ptb_50 {
    padding: 50px 0;
}

.ptb_100 {
    padding: 40px 0;
}

.ptb_150 {
    padding: 150px 0;
}

.ptb_180 {
    padding: 180px 0;
}

/*BUTTONS*/
.btn {
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    line-height: 1;
    text-align: center;
    padding: 18px 30px;
    border: 0 none;
    border-radius: 6px;
    outline: 0 none;
    position: relative;
    z-index: 1;
}

.btn:hover,
.btn:focus,
.btn:active {
    color: #fff;
    -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.btn.sApp-btn {
    background: transparent none repeat scroll 0 0;
    color: #444;
    border-radius: 100px;
    letter-spacing: 1px;
}

.btn.sApp-btn:hover,
.btn.sApp-btn:focus {
    color: #fff;
}

.btn.sApp-btn:before {
    position: absolute;
    content: "";
    height: calc(100% + 4px);
    width: calc(100% + 4px);
    top: -2px;
    left: -2px;
    border-radius: 100px;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    z-index: -2;
}

.btn.sApp-btn:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 100px;
    background: #fff none repeat scroll 0 0;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    z-index: -1;
}

.btn.sApp-btn:hover:after,
.btn.sApp-btn:focus:after {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

.btn-success {
    background: #28a745 !important;
    border-color: #28a745 !important;
}

.button-group {
    margin-top: 30px;
}

.button-group a {
    margin-right: 10px;
}

.button-group a:last-child {
    margin-right: 0;
}

.store-buttons a {
    text-align: left;
}

.store-buttons img {
    max-width: 190px;
}

.store-buttons.store-black img {
    max-width: 170px;
    margin-bottom: 10px;
}

/*BG SHAPE*/
.shapes-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.shape {
    position: relative;
}

.bg-shape {
    position: absolute;
    height: 190%;
    width: 100%;
    display: block;
    border-radius: 120px;
    background: linear-gradient(-47deg, #8731E8 0%, #4528DC 100%);
    bottom: 0;
    right: 0;
    -webkit-transform: translate(35%,-28%) rotate(-35deg);
    transform: translate(35%,-28%) rotate(-35deg);
    z-index: 0;
}

@media (max-width: 991px) {
    .bg-shape {
        height: 130%;
    }
}

/*SHAPE DIVIDER*/
.shape-bottom {
    position: absolute;
    top: auto;
    bottom: -1px;
    left: 0;
    right: 0;
}

.homepage-5 .shape-bottom svg {
    overflow: hidden;
    width: 100%;
    line-height: 0;
    direction: ltr;
    transform: rotate(180deg);
}

/*BACKGROUND OVERLAY*/
.bg-overlay,
.overlay-dark {
    position: relative;
    z-index: 0;
}

.bg-overlay::after,
.overlay-dark:after {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

.bg-overlay::after {
    opacity: 0.94;
    z-index: -1;
}

.overlay-dark::after {
    background-color: rgba(16, 16, 45, 1);
    opacity: 0.80;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
    z-index: -1;
}

/*BACKGROUND COLORS*/
.bg-gray {
    background-color: #f6f9fe;
}
.bg-inherit {
    background: inherit !important;
}


.btn,
.btn:active,
.btn.sApp-btn:before,{
    background: #dc3545;
}



.bg-overlay::after,
.benifits-item:after {
    background: linear-gradient(-47deg, #8731e817 0%, #4528DC 100%)
}

/*SOCIAL ICONS*/
.social-icons > a {
    margin: 5px;
    width: 45px;
    height: 45px;
    font-size: 20px;
    color: #fff;
    border-radius: 3px;
    overflow: hidden;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.social-icons svg {
    display: block;
    height: 100%;
    line-height: 45px;
    margin: 0 auto;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.social-icons > a:hover svg:first-child {
    margin-top: -45px;
}

.social-icons > a.facebook {
    background-color: #3b5999 !important;
}

.social-icons > a.twitter {
    background-color: #55acee !important;
}

.social-icons > a.google-plus {
    background-color: #dd4b39 !important;
}

.social-icons > a.vine {
    background-color: #00b489 !important;
}

/*BACKGROUND IMAGES*/

/* ******************************
:: 5.0 PRELOADER AREA CSS
****************************** */
.preloader-main {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 99999;
}

.preloader-main .preloader-wapper {
    height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.preloader-main .loader-section {
    position: fixed;
    top: 0;
    height: 100%;
    width: calc(50% + 1px);
    background-color: #fff;
}

.preloader-main .loader-section.section-left {
    left: 0;
}

.preloader-main .loader-section.section-right {
    right: 0;
}

.preloader-main .preloader {
    position: relative;
    display: block;
    height: 80px;
    width: 170px;
    top: -40px;
    z-index: 99;
}

.preloader-main .loaded .section-right {
    -webkit-transform: translateX(101%);
    transform: translateX(101%);
    -webkit-transition: 0.7s 0.3s all cubic-bezier(0.1, 0.1, 0.1, 1);
    transition: 0.7s 0.3s all cubic-bezier(0.1, 0.1, 0.1, 1);
}

.preloader-main .loaded .section-left {
    -webkit-transform: translateX(-101%);
    transform: translateX(-101%);
    -webkit-transition: 0.7s 0.3s all cubic-bezier(0.1, 0.1, 0.1, 1);
    transition: 0.7s 0.3s all cubic-bezier(0.1, 0.1, 0.1, 1);
}

.preloader-main .loaded .preloader {
    -webkit-transition: 0.3s ease-out;
    transition: 0.3s ease-out;
    visibility: hidden;
    opacity: 0;
}

.preloader-main circle.dot:nth-of-type(1) {
    -webkit-animation: slide 2s ease infinite;
    animation: slide 2s ease infinite;
}

.preloader-main circle.dot:nth-of-type(2) {
    -webkit-transform: translateX(55px);
    transform: translateX(55px);
    -webkit-animation: slide 2s ease infinite;
    animation: slide 2s ease infinite;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

@-webkit-keyframes slide {
    0%,
    100% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }

    50% {
        -webkit-transform: translateX(70px);
        transform: translateX(70px);
    }
}

@keyframes slide {
    0%,
    100% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }

    50% {
        -webkit-transform: translateX(70px);
        transform: translateX(70px);
    }
}

/* ******************************
:: 6.0 SCROLL TO TOP AREA CSS
****************************** */
#scrollUp {
    position: fixed;
    right: 2%;
    bottom: 3%;
    height: 45px;
    width: 40px;
    border-radius: 4px;
    background-color: rgba(35,35,35,0.65);
    text-align: center;
    cursor: pointer;
    z-index: 500;
    display: none;
    -webkit-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

#scrollUp > svg {
    display: block;
    height: 100%;
    font-size: 22px;
    color: #fff;
    margin: 0 auto;
}

#scrollUp:hover {
    background-color: #666;
}

/* ******************************
:: 7.0 HEADER AREA CSS
****************************** */
.navbar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 0;
    padding-bottom: 0;
    z-index: 999;
    -webkit-transition: .3s;
    transition: .3s;
    background: transparent;
}

.navbar .navbar-nav .nav-link {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    -webkit-transition: .3s;
    transition: .3s;
}

.navbar .navbar-brand-sticky {
    display: none;
}

.navbar-sticky {
    -webkit-transition: none;
    transition: none;
}

.navbar-sticky-transitioned {
    -webkit-transition: .3s;
    transition: .3s;
}

.navbar-sticky-moved-up {
    position: fixed;
    top: 0;
    background: #fff;
    margin-top: -100px;
}

[data-theme=dark] .navbar-sticky-moved-up {
    background: #01081f;
    -webkit-box-shadow: 0 4px 25px rgba(30, 39, 69, 0.3), 0 1px 0px rgba(30, 39, 69, 0.4);
    box-shadow: 0 4px 25px rgba(30, 39, 69, 0.3), 0 1px 0px rgba(30, 39, 69, 0.4);
}

.navbar-sticky-on {
    margin-top: 0;
    -webkit-transition: .3s;
    transition: .3s;
    -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 20px;
}

.navbar-sticky-on .navbar-brand-regular {
    display: none;
}

.navbar-sticky-on .navbar-brand-sticky {
    display: inline;
}

/*NAVBAR DARK*/
.navbar-dark .navbar-nav .nav-link {
    color: #fff;
}

.navbar-dark .btn-outline {
    color: #fff;
}

.navbar-dark.navbar-sticky-on .navbar-nav .nav-link {
    color: #7e8085;
}

[data-theme=dark] .navbar-dark.navbar-sticky-on .navbar-nav .nav-link {
    color: #e7eaee;
}

.navbar-dark.navbar-sticky-on .navbar-nav .nav-link:hover,
.navbar-dark.navbar-sticky-on .navbar-nav .nav-link:focus,
.navbar-dark.navbar-sticky-on .navbar-nav .nav-link.active,
.navbar-dark.navbar-sticky-on .navbar-nav .nav-link.current-menu-item {
    color: #2c2e30;
}

[data-theme=dark] .navbar-dark.navbar-sticky-on .navbar-nav .nav-link:hover,
[data-theme=dark] .navbar-dark.navbar-sticky-on .navbar-nav .nav-link:focus,
[data-theme=dark] .navbar-dark.navbar-sticky-on .navbar-nav .nav-link.active,
[data-theme=dark] .navbar-dark.navbar-sticky-on .navbar-nav .nav-link.current-menu-item {
    color: #D2D5DA;
}


/*---------- bootstrap nav custom hover ----------*/
.dropdown-menu {
  border: 0;
  padding: 0.625rem 0;
  margin: 0;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

.dropdown-menu > li {
  padding: 0 0.625rem;
}

.dropdown > .dropdown-menu:before {
  content: '';
  position: absolute !important;
  top: -10px;
  right: auto;
  bottom: auto;
  left: 10px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
}

.dropdown-submenu > .dropdown-menu:before {
  content: '';
  position: absolute !important;
  top: 30px;
  right: auto;
  bottom: auto;
  left: -10px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #fff;
}

.dropdown-item {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  text-transform: uppercase;
  font-size: 0.8125rem;
  font-weight: 700;
  color: #2c2e30;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

.dropdown-item.active, .dropdown-item:active {
  background: #f8f9fa;
}

.dropdown-divider {
  border-color: #8d98b7;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
}

/*  mainmenu caret */
.dropdown-toggle:after {
  margin-left: 4px;
  vertical-align: 1px;
  border-top: 5px solid;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  color: #d3d3d3;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

/*  submenu cater */
.dropdown-submenu .dropdown-toggle:after {
  position: absolute;
  right: 24px;
  top: 20px;
  color: #d3d3d3;
}

.dropdown-submenu:hover .dropdown-toggle:after {
  text-decoration: underline;
  -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

/* ******************************
:: 8.0 WELCOME AREA CSS
****************************** */
.welcome-area {
    height: 800px;
    z-index: 1;
}

.welcome-thumb {
    max-width: 300px;
    padding-top: 70px;
}

@media (min-width: 992px) {
    .download-page .welcome-intro > h1 {
        font-size: 3.8em;
    }

    .download-page .welcome-intro p {
        font-size: 16px;
    }
}

.welcome-area .video-icon {
    position: absolute;
    top: 50%;
    left: 70%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.play-btn {
    position: relative;
    display: inline-block;
    height: 100px;
    width: 100px;

    border-radius: 50%;
}

.btn-circle {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);
}

.play-icon {
    font-size: 22px;
    color: #7c4fe0;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

@-webkit-keyframes grow {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }

    to {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        opacity: 0;
    }
}

@keyframes grow {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }

    to {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        opacity: 0;
    }
}

.play-animation {
    -webkit-animation: grow 3s infinite;
    animation: grow 3s infinite;
}

.animation-short {
    -webkit-animation-delay: .5s;
    animation-delay: .5s;
}

.welcome-area.bg-overlay::after {
    z-index: -2;
}

.welcome-area .shape-bottom {
    z-index: -1;
}

/* ***********************************
:: 8.1 WELCOME AREA HOMEPAGE-3 CSS
**************************************/
@media (min-width: 992px) {
    .homepage-2 .welcome-thumb {
        max-width: 400px;
    }
}
.homepage-3 .welcome-area .subscribe-form {
    position: relative;
    display: block;
    width: 80%;
}

.homepage-3 .welcome-area .subscribe-form input {
    font-size: 14px;
    border-radius: 10px;
    text-indent: 3%;
    -webkit-box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28);
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28);
}

.homepage-3 .welcome-area .subscribe-form button {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
}

.homepage-3 .welcome-area .shape-bottom svg {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}

/* ***********************************
:: 8.2 WELCOME AREA HOMEPAGE-4 CSS
**************************************/
.homepage-4 .welcome-thumb {
    max-width: 100%;
}

.homepage-4 .welcome-thumb > img {
    position: relative;
    -webkit-animation: bounceHero 3s infinite;
    animation: bounceHero 3s infinite;
}

@-webkit-keyframes bounceHero {
    0% {
        top: 0px;
    }

    50% {
        top: 25px;
    }

    100% {
        top: 0px;
    }
}

@keyframes bounceHero {
    0% {
        top: 0px;
    }

    50% {
        top: 25px;
    }

    100% {
        top: 0px;
    }
}

/* ***********************************
:: 8.3 WELCOME AREA HOMEPAGE-6 CSS
**************************************/
.homepage-6 .shape-bottom {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.homepage-6 .shape-bottom svg {
    -webkit-transform: translateX(-50%) rotateY(180deg);
    transform: translateX(-50%) rotateY(180deg);
}

.homepage-6 .shape-bottom svg {
    display: block;
    width: calc(100% + 1.3px);
    position: relative;
    left: 50%;
}

/* ******************************
:: 9.0 COUNTER AREA CSS
****************************** */
.single-counter {
    position: relative;
}

.single-counter::after {
    position: absolute;
    content: '';
    height: 70%;
    width: 1px;
    background-color: #222;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    opacity: 0.15;
}

.counter-area .col-5.single-counter:last-of-type::after {
    display: none;
}

.single-counter span {
    font-size: 3em;
    background: linear-gradient(-47deg, #8731E8 0%, #4528DC 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* ******************************
:: 10.0 FEATURES AREA CSS
****************************** */
.features-slider-wrapper {
    padding: 67px 23px 90px 26px;
    background-size: 100%;
    max-width: 290px;
    margin: 0 auto;
}

.features-area.style-two .image-box {
    position: relative;
    -webkit-box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.12);
    border-radius: 1.5rem;
    -webkit-transition: -webkit-transform 0.3s ease 0s;
    transition: -webkit-transform 0.3s ease 0s;
    transition: transform 0.3s ease 0s;
    transition: transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
}

.features-area.style-two .image-box:hover {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    -webkit-box-shadow: 0 1rem 3rem rgba(31, 45, 61, .125) !important;
    box-shadow: 0 1rem 3rem rgba(31, 45, 61, .125) !important;
}

/* ******************************
:: 11.0 SERVICE AREA CSS
****************************** */
.service-icon span {
    height: 54px;
    width: 54px;
    display: inline-block;
    text-align: center;
    line-height: 54px;
    font-size: 24px;
    color: #7c4fe0;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.service-list.style-two > li {
    list-style: disc;
    margin-left: 1rem;
}

/* ******************************
:: 12.0 DISCOVER AREA CSS
****************************** */
.list-box .icon {
    color: #764fe0;
}

@media (max-width: 575px) {
    .discover-thumb {
        max-width: 300px;
    }
}

/* ******************************
:: 13.0 WORK AREA CSS
****************************** */
.single-work {
    position: relative;
}

.single-work::after {
    position: absolute;
    content: '\f054';
    font-size: 45px;
    font-weight: 900;
    color: #fff;
    font-family: 'Font Awesome 5 Free';
    top: 50%;
    right: 0;
    margin-right: -50px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: transform 0.3s;
    transition: transform 0.3s;
}

.single-work:hover::after {
    -webkit-transform: translateX(5px) translateY(-50%);
    transform: translateX(5px) translateY(-50%);
}

.work-area .col-12:last-of-type .single-work::after {
    display: none;
}

@media (max-width: 767px) {
    .single-work::after {
        display: none;
    }
}

/* ******************************
:: 14.0 SCREENSHOTS AREA CSS
****************************** */
.single-screenshot {
    position: relative;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
}

.slick-dots {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    left: 50%;
    bottom: -40px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.slick-dots li button {
    font-size: 0;
    height: 8px;
    width: 8px;
    background-color: rgba(0, 0, 0, 0.1);
    border: none;
    outline: none;
    border-radius: 50%;
    margin: 5px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.slick-dots .slick-active button,
.testimonial-area .owl-carousel button.owl-dot.active {
    background-color: #7c4fe0;
    -webkit-box-shadow: 0 1px 5px #7c4fe0;
    box-shadow: 0 1px 5px #7c4fe0;
}

/* ******************************
:: 15.0 REVIEWS AREA CSS
****************************** */
.single-review {
    -webkit-box-shadow: 0 0.313rem 0.875rem rgba(45, 49, 54, 0.09);
    box-shadow: 0 0.313rem 0.875rem rgba(45, 49, 54, 0.09);
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.single-review .card-top {
    position: relative;
}

.single-review .quot-icon {
    position: absolute;
    top: 5%;
    right: 5%;
}

.single-review .reviewer {
    position: relative;
}

.single-review .reviewer::after {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    background-color: #f5f7fb;
    bottom: calc(100% - 10px);
    left: 15%;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.single-review:hover {
    -webkit-box-shadow: 0 1rem 3rem rgba(31, 45, 61, .125);
    box-shadow: 0 1rem 3rem rgba(31, 45, 61, .125);
}

/* ******************************
:: 16.0 TESTIMONIAL AREA CSS
****************************** */
.testimonial-area .owl-item img {
    width: 100px;
    height: 100px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
}

.client-rating {
    color: #ffa200;
}

.single-testimonial-thumb img {
    width: 100%;
    height: 100%;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    overflow: hidden;
}

.testimonial-area .owl-dots {
    position: absolute;
    left: 50%;
    bottom: 0;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.testimonial-area .owl-carousel button.owl-dot {
    height: 8px;
    width: 8px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    outline: none;
    margin: 5px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

/* ******************************
:: 17.0 PRICE PLAN AREA CSS
****************************** */
.single-price-plan {
    -webkit-box-shadow: 0 0.313rem 0.875rem rgba(45, 49, 54, 0.09);
    box-shadow: 0 0.313rem 0.875rem rgba(45, 49, 54, 0.09);
    background-color: #fff;
    border-radius: 1rem;
}

.plan-title > h3 {
    letter-spacing: 3px;
    opacity: 0.8;
}

.plan-price > h1 > small {
    font-size: 60%;
}

.plan-button {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

/* ******************************
:: 18.0 FAQ AREA CSS
****************************** */
.card-header .btn {
    position: relative;
    display: block;
    width: 100%;
    background: transparent;
    color: #222;
    text-align: left;
}

.card-header .btn.collapsed {
    color: #444;
}

.card-header .btn:hover,
.card-header .btn:focus,
.card-header .btn:active {
    -webkit-box-shadow: inherit;
    box-shadow: inherit;
}

.card-header .btn::after,
.card-header .btn.collapsed::after {
    position: absolute;
    top: 50%;
    left: calc(100% - 1.25rem);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 18px;
    font-weight: 900;
    font-family: 'Font Awesome 5 Free';
}

.card-header .btn::after {
    content: '\f107';
}

.card-header .btn.collapsed::after {
    content: '\f105';
}

.faq-area.style-two .card-header .btn::after {
    display: none;
}

.faq-area.style-two .card-header .btn {
    cursor: inherit;
}

/* ******************************
:: 19.0 TEAM AREA CSS
****************************** */
.single-team {
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.team-thumb > img {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.team-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.team-overlay::after {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #7c4fe0;
    border-radius: 100px;
    opacity: 0.9;
    z-index: -1;
}

.team-name {
    font-size: 18px;
}

.team-post {
    font-size: 15px;
}

.team-icons svg {
    font-size: 16px;
    color: #fff;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: -webkit-transform 0.3s ease 0s;
    transition: -webkit-transform 0.3s ease 0s;
    transition: transform 0.3s ease 0s;
    transition: transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
}

.team-icons a:hover svg {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}

.single-team:hover .team-overlay {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.single-team:hover .team-thumb > img {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}

/* ******************************
:: 20.0 SUBSCRIBE AREA CSS
****************************** */
.form-control {
    font-size: 15px;
    height: 60px;
    border: none;
    outline: none;
    padding-left: 20px;
    border-radius: 8px;
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.form-control:focus {
    border: none;
    outline: none;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.subscribe-form .form-group input {
    display: block;
    text-align: center;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.subscribe-form .form-group input::-webkit-input-placeholder {
    text-align: center;
}

.subscribe-form .form-group input:-ms-input-placeholder {
    text-align: center;
}

.subscribe-form .form-group input::placeholder {
    text-align: center;
}

.subscribe-form button {
    height: 60px;
    border-radius: 8px;
    background: #7c4fe0 !important;
}

/* ******************************
:: 21.0 BLOG AREA CSS
****************************** */
.blog-area {
    z-index: 1;
}

.single-blog {
    background-color: #fff;
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
}

.blog-thumb img {
    display: block;
    width: 100%;
    border-radius: 6px;
}

.blog-content > p {
    line-height: 1.8;
}

.blog-btn {
    position: relative;
    display: inline-block;
}

.blog-btn::after {
    position: absolute;
    content: '\f101';
    font-weight: 900;
    font-family: 'Font Awesome 5 Free';
    opacity: 0;
    visibility: hidden;
    top: 50%;
    -webkit-transform: translateX(3px) translateY(-50%);
    transform: translateX(3px) translateY(-50%);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.blog-btn:hover::after,
.blog-btn:focus::after {
    visibility: visible;
    opacity: 1;
    top: 50%;
    -webkit-transform: translateX(5px) translateY(-50%);
    transform: translateX(5px) translateY(-50%);
}

.single-blog:hover {
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
}

/* ******************************
:: 22.0 BREADCRUMB AREA CSS
****************************** */
.breadcrumb-area {
    height: 450px;
    z-index: 1;
}

.breadcrumb-content > h3 {
    font-size: 38px;
}

.breadcrumb {
    background-color: transparent;
    margin: 0;
}

.breadcrumb-item + .breadcrumb-item::before {
    content: ">";
    color: #fff;
}

.blog .breadcrumb-item > a,
.blog .breadcrumb-item,
.blog .breadcrumb-item::before,
.blog .breadcrumb-item.active {
    color: #fff;
}

/* ******************************
:: 23.0 BLOG PAGE AREA CSS
****************************** */
.blog .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.blog .single-blog {
    margin-bottom: 45px;
}

.pagination > li > a {
    font-size: 14px;
    height: 40px;
    width: 40px;
    line-height: 40px;
    display: inline-block;
    border: 1px solid #444;
    color: #444;
    text-align: center;
}

.pagination li:first-child a,
.pagination li:last-child a {
    display: block;
    border: none;
    position: relative;
}

.pagination li:first-child:hover a,
.pagination li:last-child:hover a {
    background: none;
    color: inherit;
    border: none;
}

.pagination li:first-child:hover a {
    -webkit-transform: translateX(-4px);
    transform: translateX(-4px);
}

.pagination li:last-child:hover a {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
}

/* **********************************
:: 24.0 BLOG PAGE DETAILS AREA CSS
*************************************/
/*Single Widget*/
.single-widget {
    margin-bottom: 35px;
}

.sidebar .single-widget:last-child {
    margin-bottom: 0;
}

.widget-content {
    position: relative;
}

/*Search Widget*/
.search-widget input {
    background-color: #f7f7f7;
    border: 1px solid #eee;
    color: #444;
    height: 45px;
    padding: 10px 15px;
    width: 100%;
    -webkit-transition: -webkit-box-shadow 1s ease 0s;
    transition: -webkit-box-shadow 1s ease 0s;
    transition: box-shadow 1s ease 0s;
    transition: box-shadow 1s ease 0s, -webkit-box-shadow 1s ease 0s;
}

.search-widget input:focus {
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

/*Catagory Widget*/
.widget .single-accordion {
    border: 1px solid #eee;
    border-radius: 4px;
}

.widget .single-accordion h5 a {
    color: #444;
    border-bottom: 1px solid #eee;
}

.widget-items li a {
    position: relative;
    border-bottom: 1px solid #eee;
}

.widget-items li a.active {
    color: #7c4fe0;
}

.widget-items li a::before {
    position: absolute;
    content: '';
    height: 100%;
    width: 0px;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    -webkit-transition: all 0.1s ease 0s;
    transition: all 0.1s ease 0s;
}

.widget-items li a:hover,
.widget-items li a:focus,
.widget-items li a.active {
    background-color: #f7f7f7;
}

.widget-items li a:hover::before,
.widget-items li a:focus::before,
.widget-items li a.active::before {
    width: 2px;
    background-color: #7c4fe0;
}

.widget-items li a span {
    line-height: 1.4;
}

/*Post Widget*/
.post-thumb img {
    border: 1px solid #eee;
}

.post-widget .widget-items .post-date {
    font-size: 13px;
    line-height: 1;
}

.post-content h6 {
    font-weight: 400;
    line-height: 20px;
}

/*Tags Widget*/
.tags-widget .single-accordion {
    border: none;
}

.tags-widget-items a {
    border: 1px solid #e5e5e5;
    font-size: 12px;
}

/*Blog Details*/
.sApp-blog .meta-info {
    border-bottom: 1px solid #eee;
}

.meta-info > ul > li {
    position: relative;
}

.meta-info > ul > li::after {
    position: absolute;
    content: '';
    height: 30%;
    width: 2px;
    background-color: #777;
    top: 50%;
    left: 100%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.meta-info > ul > li:last-child::after {
    display: none;
}

.sApp-blog .blog-share a {
    padding: 0 10px;
}

.blog-share .social-icons > a {
    width: 35px;
    height: 35px;
    font-size: 16px;
}

.blog-share .social-icons > a.facebook:hover {
    background-color: #3b5999 !important;
    color: #fff;
}

.blog-share .social-icons > a.twitter:hover {
    background-color: #55acee !important;
    color: #fff;
}

.blog-share .social-icons > a.google-plus:hover {
    background-color: #dd4b39 !important;
    color: #fff;
}

.blog-share .social-icons svg {
    line-height: 35px;
}

.blog-share .social-icons > a:hover svg:first-child {
    margin-top: -35px;
}

.sApp-blog .blog-details .blog-title > a {
    font-size: 24px;
}

.blockquote {
    background-color: #f7f7f7;
    position: relative;
}

.blockquote::after {
    position: absolute;
    content: '';
    height: 100%;
    width: 2px;
    top: 0;
    left: 0;
}

.blog-comments {
    margin-top: 20px;
}

.admin {
    border-top: 1px solid #e5e5e5;
}

.admin-thumb img,
.comments-thumb img {
    border: 1px solid #eee;
}

.comments,
.blog-contact {
    margin-left: 200px;
}

.single-comments {
    border: 1px solid #eee;
    margin-bottom: 1rem;
}

.single-comments:last-of-type {
    margin-bottom: 0;
}

.comments-content > h5 > a:last-child {
    font-size: 14px;
    font-weight: 500;
    color: #7c4fe0;
}

.contact-box.comment-box {
    text-align: left;
}

.contact-box.comment-box .form-group input {
    font-size: 15px;
    border: 1px solid #e5e5e5;
}

.contact-box.comment-box .form-group textarea {
    font-size: 15px;
    border: 1px solid #e5e5e5;
    height: 150px;
}

.recent-blog-area {
    padding-top: 80px;
}

/* ******************************
:: 25.0 CONTACT AREA CSS
****************************** */
.contact-us .social-icon {
    display: inline-block;
    height: 45px;
    width: 45px;
    line-height: 45px;
    font-size: 18px;
    text-align: center;
    background-color: #7c4fe0;
    color: #fff;
    border-radius: 4px;
}

.contact-box .form-group input,
.contact-box .form-group textarea {
    font-size: 14px;
    height: 50px;
    padding: 0 15px;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.contact-box .form-group textarea {
    height: 160px;
    padding: 15px;
}

.contact-box .form-group input::-webkit-input-placeholder,
.contact-box .form-group textarea::-webkit-input-placeholder {
    color: rgba(68, 68, 68, 0.6);
}

.contact-box .form-group input:-ms-input-placeholder,
.contact-box .form-group textarea:-ms-input-placeholder {
    color: rgba(68, 68, 68, 0.6);
}

.contact-box .form-group input::placeholder,
.contact-box .form-group textarea::placeholder {
    color: rgba(68, 68, 68, 0.6);
}

.contact-box button {
    height: 60px;
    border-radius: 8px;
    background: #7c4fe0 !important;
}

/*Map Area*/
.map-area {
    height: 550px;
    width: 100%;
}

.map-area iframe {
    height: 100%;
    width: 100%;
}

@media (max-width: 575px) {
    .map-area {
        height: 450px;
    }
}

.homepage-5 .contact-top > h3 {
    font-size: 2em;
}
.homepage-5 .contact-top h5 {
    line-height: 1.4;
}

.contact-bottom > span {
    font-size: 13px;
}
.contact-bottom > span a {
    text-decoration: underline;
    color: #222;
}

/* ******************************
:: 26.0 FOOTER AREA CSS
****************************** */
.height-emulator {
    float: left;
    height: 585px;
}

.footer-area .social-icons > a {
    width: 40px;
    height: 40px;
    font-size: 18px;
}

.footer-area .social-icons > a:hover svg:first-child {
    margin-top: -40px;
}

.copyright-area {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
}

/* ******************************
:: 27.0 ERROR AREA CSS
****************************** */
.error-area h1 {
    font-size: 18vmax;
}

@media (max-width: 767px) {
    .error-area h1 {
        font-size: 8em !important;
    }
}

@media (max-width: 575px) {
    .error-area h1 {
        font-size: 7em !important;
    }
}

/* ******************************
:: 28.0 COMING SOON AREA CSS
****************************** */
.countdown {
    text-transform: uppercase;
    color: #fff;
}

.countdown > div {
    display: inline-block;
}

.countdown > div > span {
    display: block;
    text-align: center;
}

.countdown-container {
    margin: 0 10px;
    padding: 1rem;
}

.countdown-content span {
    font-size: 1.5em;
}

.countdown-container .countdown-heading {
    font-size: 16px !important;
    font-weight: 600;
    margin: 10px;
}

.countdown-container .countdown-value {
    font-size: 50px;
    font-weight: 600;
    padding: 10px;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4)
}

@media (max-width: 767px) {
    .countdown-container {
        margin: 0 6px;
        padding: 0.5rem;
    }
}

@media (max-width: 575px) {
    .countdown-container {
        margin: 0 2px;
        padding: 0;
    }
    .countdown-container .countdown-value {
        font-size: 40px;
    }
}

/* ******************************
:: 29.0 PREVIEW AREA CSS
****************************** */
.preview .welcome-intro h2 {
    line-height: 1.2;
}

.single-preview >a > img {
    box-shadow: 0 0.313rem 0.875rem rgba(45, 49, 54, 0.09);
    border-radius: 8px;
    transition: all 0.3s ease 0s;
}

.single-preview h3 {
    font-size: 16px;
    color: #555;
    transition: all 0.3s ease 0s;
}

.single-preview:hover > a > img {
    transform: translateY(-10px);
    box-shadow: 0 0.313rem 0.875rem rgba(45, 49, 54, 0.12);
}

@media (min-width: 992px) {
    .preview .welcome-intro p {
        font-size: 15px;
        line-height: 28px;
    }
}

@media (max-width: 991px) {
    .preview .welcome-area {
        background-position: unset;
    }
}

@media (max-width: 767px) {
    .preview .welcome-area {
        height: 500px !important;
        padding-top: 0;
    }
}

@media (max-width: 767px) {
.navbar-brand-regular{
	width:120px;
}



.ptb_10
{
    
    padding: 50px 0;
}
`;