import React, { Component, useState, useEffect } from 'react'
import { Container, Row, Col, Form } from 'react-bootstrap'
import Header from '../../../components/Header'
import BreadcrumbBox from '../../../components/common/Breadcrumb'
import { Styles } from './style/undergraduate'
import Footer from '../../../components/Footer'
import { Link } from 'react-router-dom'
import CourseSearch from '../CourseSearch'
import PopularProgram from '../PopularProgram'
import InfiniteScroll from 'react-infinite-scroll-component'
import QuickLinks from '../../../components/QuickLinks'
import SearchResearcher from '../../../components/SearchResearcher'
const Undergraduate = () => {
  const [pgdata, setPgata] = useState([])

  var result_pg = []

  useEffect(() => {
    fetch('https://api.shooliniuniversity.com/programAPI', {
      method: 'post',
      headers: new Headers({
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify({ auth: 'shoolini@999', undergraduate: 1 }),
    })
      .then((response) => response.json())
      .then((res) => setPgata(res))
  }, [])

  result_pg = pgdata?.success

  var s = document.createElement('script')
  s.type = 'text/javascript'
  s.async = true
  s.src = 'https://widgets.nopaperforms.com/emwgts.js'
  document.body.appendChild(s)

  return (
    <>
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper undergraduate-page">
          {/* Header 2 */}
          <Header />
          {/* Breadcroumb */}
          <BreadcrumbBox title="Undergraduate Programs" />
          <section className="py-5">
            <Container>
              <Row>
                <Col md="8">
                  <Row>
                    {result_pg?.map((data, index) => (
                      <Col md="6">
                        <div className="cuCourse">
                          <Link
                            to={`/${data?.slug}`}
                            className="detailView"
                          >
                            <div key={index} value={data?.id}>
                              {' '}
                              <h3> {data?.title} </h3>
                              <p>
                                {' '}
                                <strong> Eligibility </strong>
                                {data?.eligibility}{' '}
                              </p>
                              <span>
                                {' '}
                                <strong> Duration </strong>
                                {data?.duration}{' '}
                              </span>
                            </div>
                          </Link>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Col>
                <Col md="4">
                  <div className="sidebar-content sideBarpage">
                    <SearchResearcher />

                    <div className="sideBarNew">
                      <div className="enqForm">
                        <div
                          className="npf_wgts"
                          data-height="500px"
                          data-w="7d1b402b547afa4d6a10968170a856b6"
                        ></div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <Footer />
        </div>
      </Styles>
    </>
  )
}

export default Undergraduate
