import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Styles } from './common/styles/header'
import StickyMenu from './common/StickyMenu'
import MobileMenu from './common/MobileMenu'
import Search from './common/Search'
import EnquireForMob  from './EnquireForMob'
import SocialShare from './SocialShare'
import { FaAlignLeft } from "react-icons/fa";
import Topbar from './Topbar'

const Header = () => {

    
  const [isActive, setActive] = useState("false");

  const handleToggle = () => {
    setActive(!isActive);
  };

  const [Data, setData] = useState([])

  useEffect(() => {
    fetch('https://api.shooliniuniversity.com/GetTopnavAPI', {
      method: 'post',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        auth: 'shoolini@999',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setData(res?.success)
        // console.log(res?.success)
      })
  }, [])

  return (
    <>
      <Styles>
     
      {/* <button className="toggleTopbar" onClick={handleToggle}><FaAlignLeft /></button>
    */}

        <section className="position-relative">
          <div className="top-bar" > 
          <div className="container-fluid">
            <Row className="m-0">
              <Col lg="12" md="12">
                <div className="bar-right d-md-flex xs-show justify-content-end">
                  <ul className="list-unstyled list-inline bar-login mb-0">
                  <li className="list-inline-item">
                     <Link target="_blank" to="/blog"> 
                     Blogs
                        </Link>
                    </li>
                  <li className="list-inline-item">
                     <Link to="/Internship"> 
                     Internship
                        </Link>
                    </li>

                    <li className="list-inline-item">
                      <a
                        target="_blank"
                        href="https://my.shooliniuniversity.com/"
                      >
                        SU Students
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        target="_blank"
                        href="https://alumni.shooliniuniversity.com/"
                      >
                        Alumni
                      </a>
                    </li>   
                    <li className="list-inline-item">
                      <a
                        target="_blank"
                        href="/national-academic-depository"
                      >
                        UGC-NAD
                      </a>
                    </li>
                  </ul>
                  <ul className="list-unstyled list-inline bar-lang mb-0">
                    <li className="list-inline-item">
                      <Dropdown>
                        <Dropdown.Toggle as="a">
                          Online Payment<i className="las la-angle-down"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu as="ul">
                          <Dropdown.Item as="li">
                            <a
                              target="_blank"
                              href="https://api.shooliniuniversity.com/pay/"
                            >
                              {' '}
                              Pay Tuition Fee{' '}
                            </a>{' '}
                          </Dropdown.Item>
                          <Dropdown.Item as="li">
                            <a
                              target="_blank"
                              href="https://easypay.axisbank.co.in/easyPay/makePayment?mid=NDcwNjg%3D"
                            >
                              {' '}
                              Pay Hostel Fee{' '}
                            </a>{' '}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                  </ul>
                  <ul className="list-unstyled list-inline bar-lang mb-0">
                    <li className="list-inline-item">
                      <Dropdown>
                        <Dropdown.Toggle as="a">
                          Ranking<i className="las la-angle-down"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu as="ul">
                          <Dropdown.Item as="li">
                            <Link 
                              to="/nba"
                            >
                              {' '}
                              NBA{' '}
                            </Link>{' '}
                          </Dropdown.Item>
                          <Dropdown.Item as="li">
                            <Link
                              to="/naac-self-study-report"
                            >
                              {' '}
                              NAAC{' '}
                            </Link>{' '}
                          </Dropdown.Item>
                          <Dropdown.Item as="li">
                            <Link
                              to="/nirf"
                            >
                              {' '}
                              NIRF{' '}
                            </Link>{' '}
                          </Dropdown.Item>
                          <Dropdown.Item as="li">
                            <Link
                              to="/iqac"
                            >
                              {' '}
                              IQAC{' '}
                            </Link>{' '}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                  </ul>

                  <ul className="list-unstyled list-inline bar-social mb-0">
                  <li className="list-inline-item">
                      <a target="_blank" className="su_whats" href="https://api.whatsapp.com/send?phone=917807899735&text=Hi%20Shoolini%20University">
                      <i class="fab fa-whatsapp"></i>
                      </a>
                    </li>

                    <li className="list-inline-item">
                      <a target="_blank" className="su_fb" href="https://www.facebook.com/ShooliniUniversityOfficial">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a target="_blank" className="su_twitter" href="https://twitter.com/ShooliniUniv">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a target="_blank" className="su_linked" href="https://www.linkedin.com/school/shooliniuniversity/">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a target="_blank" className="su_insta" href="https://www.instagram.com/shooliniuniversity/">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                   
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
          </div>
        </section>






        <section className="logo-area main-menu">
        <div className="container-fluid">
      
            <Row>
              <Col md="2">
                <div className="logo">
                  <Link to={process.env.PUBLIC_URL + '/'}>
                    <img
                      className="brandLogo"
                      src={process.env.PUBLIC_URL + '/assets/images/f-logo.png'}
                      alt=""
                    />
                  </Link>
                </div>

              

              </Col>
              <Col md="10">
                <div className="main-menu-box">
                  <div className="menu-box d-flex justify-content-between">
                    <ul className="nav menu-nav">
                      {Data &&
                        Data.map((row, index) => {
                          return row?.sub_menu.length === 0 ? (
                            <li
                              className="nav-item dropdown active"
                              key={index}
                            >
                              {row.text === 'HOME' ? (
                                <Link
                                  to="/"
                                  className="nav-link dropdown-toggle"
                                >
                                  {row.text}
                                </Link>
                              ) : (
                                <Link
                                  to={`/${row.link}`}
                                  className="nav-link dropdown-toggle"
                                  data-toggle="dropdown"
                                >
                                  {row.text}
                                </Link>
                              )}
                            </li>
                          ) : (
                            <li className="nav-item dropdown" key={index}>
                              <Link
                               to={row.link && `/${row.link}`}
                                className="nav-link dropdown-toggle"
                                data-toggle="dropdown"
                              >
                                {row.text} <i className="las la-angle-down"></i>
                              </Link>
                              <ul className="dropdown list-unstyled">
                                {row?.sub_menu.map((sub, i) => {
                                  return (
                                    <li
                                      className="nav-item navItem_unpos"
                                      key={i}
                                    >
                                      {sub.text ===
                                      'All Shoolini Faculty List' ? (
                                        <Link
                                          to={`${sub.link}`}
                                          className="nav-link"
                                        >
                                          {sub.text}
                                          {sub?.sub_menu?.length > 0 && (
                                            <i className="las la-angle-down"></i>
                                          )}
                                        </Link>
                                      ) : (
                                        <Link
                                          to={`/${sub.link}`}
                                          className="nav-link"
                                        >
                                          {sub.text}
                                          {sub?.sub_menu?.length > 0 && (
                                            <i className="las la-angle-down"></i>
                                          )}
                                        </Link>
                                      )}

                                      {sub?.sub_menu?.length > 0 && (
                                        <ul className="dropdown_Last list-unstyled">
                                          {sub.sub_menu.map((submenu, idx) => {
                                            return (
                                              <li
                                                className="nav-item"
                                                key={idx}
                                              >
                                                {submenu.text ===
                                                'Test Page' ? (
                                                  <Link
                                                    to={`${submenu.link}`}
                                                    className="nav-link"
                                                  >
                                                    {submenu.text}
                                                  </Link>
                                                ) : (
                                                  <Link
                                                    to={`/${submenu.link}`}
                                                    className="nav-link"
                                                  >
                                                    {submenu.text}
                                                  </Link>
                                                )}
                                              </li>
                                            )
                                          })}
                                        </ul>
                                      )}
                                    </li>
                                  )
                                })}
                              </ul>
                            </li>
                          )
                        })}

                    
                    </ul>
                    <ul className="nav search-cart-bar">
                      <li className="nav-item search-box">
                        <Search />
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </section>

        {/* Navbar */}
        <section className="main-menu">
          <Container>
            <Row>
              <Col md="12"></Col>
            </Row>
          </Container>
        </section>

        <StickyMenu />

        <MobileMenu />
        
        <EnquireForMob />                     
        <SocialShare />               
      </Styles>
    </>
  )
}

export default Header
