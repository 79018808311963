import React from 'react'
import Collapsible from 'react-collapsible';

import { Styless } from '../style/communityProgrames'
import { Link } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa";


const CommunityLinks = () => {



    return (
        <>
            <Styless>
                <div className="healthWell colapseSidebar hidecurrent">
                    <h5> Categories Links </h5>
                    <Collapsible trigger={["Collaboration and health services", <FaAngleDown />]}> 
                        <Link to="/health-services"> Documentation </Link>
                        <Link to="/health-services-activity"> Activity </Link>
                    </Collapsible>

                    <Collapsible trigger={["Health outreach programs", <FaAngleDown />]}> 
                        <Link to="/health-outreach-programs"> Documentation </Link>
                        <Link to="/health-outreach-activity"> Activity </Link>
                    </Collapsible>

                    <Collapsible trigger={["Shared sports facilities", <FaAngleDown />]}> 
                        <Link to="/health-and-well-being-shared-sports-facilities"> Documentation </Link>
                        <Link to="/health-and-well-being-shared-sports-activity"> Activity </Link>
                    </Collapsible>

                    <Collapsible trigger={["Free sexual healthcare for students", <FaAngleDown />]}> 
                        <Link to="/health-and-well-being-free-sexual-healthcare-for-students"> Documentation </Link>
                        <Link to="/health-and-well-being-free-sexual-healthcare-for-students-activity"> Activity </Link>
                    </Collapsible>

                    <Collapsible trigger={["Mental Health support", <FaAngleDown />]}> 
                        <Link to="/health-and-well-being-mental-health-support"> Documentation </Link>
                        <Link to="/health-and-well-being-mental-health-support-activity"> Activity </Link>
                    </Collapsible>


                    <Collapsible trigger={["Smoke free policy", <FaAngleDown />]}> 
                        <Link to="/health-and-well-being-smoke-free-policy"> Documentation </Link>
                        <Link to="/Health-well-being"> Activity </Link>
                    </Collapsible>
                </div>

                <div className="energy-efficiency colapseSidebar hidecurrent">
                    <h5> Categories Links </h5>
                    <Collapsible trigger={["Energy efficiency standards", <FaAngleDown />]}> 
                        <Link to="/energy-efficiency-standards"> Documentation </Link>
                        <Link to="/energy-efficiency-standards-activity"> Activity </Link>
                    </Collapsible>

                    <Collapsible trigger={["Upgradation of existing buildings", <FaAngleDown />]}> 
                        <Link to="/upgradation-of-existing-buildings"> Documentation </Link>
                        <Link to="/upgradation-of-existing-buildings-activity"> Activity </Link>
                    </Collapsible>

                    <Collapsible trigger={["Process for carbon management", <FaAngleDown />]}> 
                        <Link to="/process-for-carbon-management"> Documentation </Link>
                        <Link to="/process-for-carbon-management-activity"> Activity </Link>
                    </Collapsible>

                    <Collapsible trigger={["Energy efficiency plan", <FaAngleDown />]}> 
                        <Link to="/energy-efficiency-plan"> Documentation </Link>
                        <Link to="/energy-efficiency-plan-activity"> Activity </Link>
                    </Collapsible>

                    <Collapsible trigger={["Energy audit", <FaAngleDown />]}> 
                        <Link to="/areas-where-energy-wastage"> Documentation </Link>
                        <Link to="/areas-where-energy-wastage-activity"> Activity </Link>
                    </Collapsible>


                    <Collapsible trigger={["Divestment policy", <FaAngleDown />]}> 
                        <Link to="/divestment-policy"> Documentation </Link>
                        <Link to="/divestment-policy-activity"> Activity </Link>
                    </Collapsible>
                    

                    <Collapsible trigger={["Programs for energy efficiency", <FaAngleDown />]}> 
                        <Link to="/programs-for-energy-efficiency"> Documentation </Link>
                        <Link to="/programs-for-energy-efficiency-activity"> Activity </Link>
                    </Collapsible>
                    

                    <Collapsible trigger={["Promoting public pledge", <FaAngleDown />]}> 
                        <Link to="/promoting-public-pledge"> Documentation </Link>
                        <Link to="/promoting-public-pledge-activity"> Activity </Link>
                    </Collapsible>
                    

                    <Collapsible trigger={["Direct services to improve energy efficiency", <FaAngleDown />]}> 
                        <Link to="/direct-services-to-improve-energy-efficiency"> Documentation </Link>
                        <Link to="/direct-services-to-improve-energy-efficiency-activity"> Activity </Link>
                    </Collapsible>
                    

                    <Collapsible trigger={["Energy-efficient technology", <FaAngleDown />]}> 
                        <Link to="/energy-efficient-technology"> Documentation </Link>
                        <Link to="/energy-efficient-technology-activity"> Activity </Link>
                    </Collapsible>
                </div>

                <div className="csr-initiatives colapseSidebar hidecurrent">
                    <h5> Categories Links </h5>
                    <Collapsible trigger={["Involvement in SDG policy development", <FaAngleDown />]}>
                        <Link to="/involvement-in-sdg-policy-development"> Documentation </Link>
                        <Link to="/involvement-in-sdg-policy-development-activity"> Activity </Link>
                    </Collapsible>

                    <Collapsible trigger={["Cross-sectoral dialogue participation", <FaAngleDown />]}>
                        <Link to="/cross-sectoral-dialogue-participation"> Documentation </Link>
                        <Link to="/cross-sectoral-dialogue-participation-activity"> Activity </Link>
                    </Collapsible>

                    <Collapsible trigger={["Collaborations", <FaAngleDown />]}>
                        <Link to="/collaborations"> Documentation </Link>
                        <Link to="/collaborations-activity"> Activity </Link>
                    </Collapsible>

                    <Collapsible trigger={["Comparative approaches", <FaAngleDown />]}>
                        <Link to="/comparative-approaches"> Documentation </Link>
                        <Link to="/comparative-approaches-activity"> Activity </Link>
                    </Collapsible>

                    <Collapsible trigger={["Partnership with NGOs", <FaAngleDown />]}>
                        <Link to="/partnership-with-ngos"> Documentation </Link>
                        <Link to="/partnership-with-ngos-activity"> Activity </Link>
                    </Collapsible>


                    <Collapsible trigger={["Commitment to meaningful education", <FaAngleDown />]}>
                        <Link to="/commitment-to-meaningful-education"> Documentation </Link>
                        <Link to="/commitment-to-meaningful-education-activity"> Activity </Link>
                    </Collapsible>

                    
                    <Collapsible trigger={["Programs addressing sustainability", <FaAngleDown />]}>
                        <Link to="/programs-addressing-sustainability"> Documentation </Link>
                        <Link to="/programs-addressing-sustainability-activity"> Activity </Link>
                    </Collapsible>

                    
                    <Collapsible trigger={["Outreach educational activities", <FaAngleDown />]}>
                        <Link to="/outreach-educational-activities"> Documentation </Link>
                        <Link to="/outreach-educational-activities-activity"> Activity </Link>
                    </Collapsible>

                    
                </div>

                <div className="inclusivity-gender-equality colapseSidebar hidecurrent">
                    <h5> Categories Links </h5>
                    <Collapsible trigger={["Women's application rate", <FaAngleDown />]}>
                        <Link to="/womens-application-rate"> Documentation </Link>
                        
                    </Collapsible>

                    <Collapsible trigger={[" Policy for Women's Access and Participation plan", <FaAngleDown />]}>
                        <Link to="/policy-for-womens-access-and-participation-plan"> Documentation </Link>
                        <Link to="/compolicy-for-womens-access-and-participation-plan-activity"> Activity </Link>
                    </Collapsible>

                    <Collapsible trigger={[" Women access schemes ", <FaAngleDown />]}>
                        <Link to="/women-access-schemes"> Documentation </Link>
                        <Link to="/women-access-schemes-activity"> Activity </Link>
                    </Collapsible>

                    <Collapsible trigger={["Encourage women applications", <FaAngleDown />]}>
                        <Link to="/encourage-women-applications"> Documentation </Link>
                        <Link to="/encourage-women-applications-activity"> Activity </Link>
                    </Collapsible>

                    <Collapsible trigger={["Non-discrimination policy against women", <FaAngleDown />]}>
                        <Link to="/non-discrimination-policy-against-women"> Documentation </Link>
                        <Link to="/non-discrimination-policy-against-women-activity"> Activity </Link>
                    </Collapsible>


                    <Collapsible trigger={["Non-discrimination policy against transgender", <FaAngleDown />]}>
                        <Link to="/non-discrimination-policy-against-transgender"> Documentation </Link>
                        <Link to="/non-discrimination-policy-against-transgender-activity"> Activity </Link>
                    </Collapsible>

                    
                    <Collapsible trigger={[" Maternity and Paternity policy", <FaAngleDown />]}>
                        <Link to="/maternity-and-paternity-policy"> Documentation </Link>
                        <Link to="/maternity-and-paternity-policy-activity"> Activity </Link>
                    </Collapsible>

                    
                    <Collapsible trigger={[" Childcare facilities for staff and faculty", <FaAngleDown />]}>
                        <Link to="/childcare-facilities-for-staff-and-faculty"> Documentation </Link>
                        <Link to="/childcare-facilities-for-staff-and-faculty-activity"> Activity </Link>
                    </Collapsible>
                    
                    <Collapsible trigger={["Women mentoring schemes", <FaAngleDown />]}>
                        <Link to="/women-mentoring-schemes"> Documentation </Link>
                        <Link to="/women-mentoring-schemes-activity"> Activity </Link>
                    </Collapsible>
                    
                    <Collapsible trigger={["Tracking Women’s Progress Rate", <FaAngleDown />]}>
                        <Link to="/tracking-of-womens-likelihood"> Documentation </Link>
                        <Link to="/tracking-of-womens-likelihood-activity"> Activity </Link>
                    </Collapsible>
                    
                    <Collapsible trigger={[" Policy protecting who report discrimination", <FaAngleDown />]}>
                        <Link to="/policy-protecting-who-report-discrimination"> Documentation </Link>
                        <Link to="/policy-protecting-who-report-discrimination-activity"> Activity </Link>
                    </Collapsible>

                    
                </div>


                <div className="safe-water colapseSidebar hidecurrent">
                    <h5> Categories Links </h5>
                    <Collapsible trigger={[" Total volume of water", <FaAngleDown />]}>
                        <Link to="/total-volume-of-water-used-in-university"> Documentation </Link>
                        <Link to="/process-in-place-to-treat-waste-water-activity"> Activity </Link>
                    </Collapsible>

                    <Collapsible trigger={[" Process in place to treat waste water", <FaAngleDown />]}>
                        <Link to="/process-in-place-to-treat-waste-water"> Documentation </Link>
                        <Link to="/process-in-place-to-treat-waste-water-activity"> Activity </Link>
                    </Collapsible>

                    <Collapsible trigger={[" Prevention of Water Pollution", <FaAngleDown />]}>
                        <Link to="/prevention-of-water-pollution"> Documentation </Link>
                    </Collapsible>

                    <Collapsible trigger={[" Free drinking water for everyone", <FaAngleDown />]}>
                        <Link to="/free-drinking-water-for-everyone"> Documentation </Link>
                        <Link to="/free-drinking-water-for-everyone-activity"> Activity </Link>
                    </Collapsible>

                    <Collapsible trigger={[" Building standards to minimise water use", <FaAngleDown />]}>
                        <Link to="/building-standards-to-minimise-water-use"> Documentation </Link>
                        <Link to="/building-standards-to-minimise-water-use-activity"> Activity </Link>
                    </Collapsible>


                    <Collapsible trigger={[" Landscapes to minimise water usage", <FaAngleDown />]}>
                        <Link to="/landscapes-to-minimise-water-usage"> Documentation </Link>
                        <Link to="/landscapes-to-minimise-water-usage-activity"> Activity </Link>
                    </Collapsible>

                    
                    <Collapsible trigger={[" Maximisation of Water Reuse", <FaAngleDown />]}>
                        <Link to="/maximisation-of-water-reuse"> Documentation </Link>
                        <Link to="/building-standards-to-minimise-water-use-activity"> Activity </Link>
                    </Collapsible>

                    
                    <Collapsible trigger={[" Reuse of water", <FaAngleDown />]}>
                        <Link to="/reuse-of-water"> Documentation </Link>
                    </Collapsible>
                    
                    <Collapsible trigger={[" Good water management", <FaAngleDown />]}>
                        <Link to="/good-water-management"> Documentation </Link>
                    </Collapsible>
                    
                    <Collapsible trigger={[" Promoting conscious water usage ", <FaAngleDown />]}>
                        <Link to="/promoting-conscious-water-usage"> Documentation </Link>
                        <Link to="/promoting-conscious-water-usage-activity"> Activity </Link>
                    </Collapsible>
                    
                    <Collapsible trigger={[" Water conservation off campus", <FaAngleDown />]}>
                        <Link to="/water-conservation-off-campus"> Documentation </Link>
                        <Link to="/water-conservation-off-campus-activity"> Activity </Link>
                    </Collapsible>

                    
                    <Collapsible trigger={[" Sustainable water extraction technologies", <FaAngleDown />]}>
                        <Link to="/sustainable-water-extraction-technologies"> Documentation </Link>
                        <Link to="/sustainable-water-extraction-technologies-activity"> Activity </Link>
                    </Collapsible>

                    
                    <Collapsible trigger={[" Water Security", <FaAngleDown />]}>
                        <Link to="/water-security"> Documentation </Link>
                    </Collapsible>

                    
                </div>


            </Styless>
        </>
    )
}

export default CommunityLinks