import React, { useEffect } from 'react'
import HeaderLanding from '../Layout/HeaderLanding'
import Footer from '../../components/Footer';
import { Container, Row, Col, Dropdown } from 'react-bootstrap'
import { Style } from './css/mba';
import Companies from '../../components/Companies';
import Aos from 'aos';
import 'aos/dist/aos.css';

const Brp = () => {



    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, [])


    return (
        <>
         
            <Style>
                <main>



                    <div className="main">

                        <header className="navbar  navbar-expand-lg navbar-dark">
                            <div className="container position-relative">
                                <a className="navbar-brand" href="https://shooliniuniversity.com/">
                                    <img className="navbar-brand-regular" src="/assets/landing-pages/logo-white.png" alt="brand-logo" />
                            
                                </a>



                            </div>
                        </header>

                        <section className="section welcome-area bg-overlay d-flex align-items-center overflow-hidden" >
                            <div className="container">
                                <div className="row align-items-center">

                                    <div className="col-12 col-md-7 col-lg-6">
                                        <div className="welcome-intro">
                                            <h1 className="text-white"><a href="#">Refer</a> your Friends</h1>
                                            <h1 className="text-white"><center> & </center> </h1>

                                            <h2 className="text-white"><a href="#form" id="blink">Earn INR 1000/- or an iPhone!!</a> </h2>


                                            <p className="text-white my-4"> Admissions open in UG/PG/PhD Programs.</p>

                                            <a href="https://api.shooliniuniversity.com/buddy?utm_source=Buddy_Referral&utm_medium=Buddy_Referral&utm_campaign=Buddy_Referral" className="button">REFER NOW</a>


                                        </div>
                                    </div>
                                    <div className="col-12 col-md-5 col-lg-6">

                                        <div className="welcome-thumb text-center mx-auto">

                                            <a className="play-btn" data-fancybox href="https://www.youtube.com/watch?v=bZZzVpUErew">

                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </section>




                        <section className="section service-area bg-gray overflow-hidden ptb_100" id="test">
                            <div className="container">
                                <div className="row justify-content-between">
                                    <div className="col-12  order-2 order-lg-1"  >

                                        <div className="service-text pt-4 pt-lg-0 px-0 px-lg-4">

                                            <h2 className="text-capitalize mb-4" >
                                                Details</h2>


                                            <div className="service-list style-two">


                                                <div className="service-text">
                                                    <p><i className="fa fa-check-circle" aria-hidden="true"></i> You qualify when your friend submits an application form.</p>
                                                </div>

                                                <div className="service-text">
                                                    <p> <i className="fa fa-check-circle" aria-hidden="true"></i> Each completed application gives you INR 1000/-</p>
                                                </div>



                                                <div className="service-text">
                                                    <p><i className="fa fa-check-circle" aria-hidden="true"></i> If you refer minimum 3 friends who complete the application process, your name enters the mega lucky draw. 3 lucky winners can get iPhones/ Tablets/ Amazon gift vouchers up to 50,000!</p>
                                                </div>




                                                <div className="service-text">
                                                    <p><i className="fa fa-check-circle" aria-hidden="true"></i> If your friends take admission @Shoolini in ANY program you win Amazon vouchers worth 3000 per admission</p>
                                                </div>



                                                <div className="service-text">
                                                    <p> <i className="fa fa-check-circle" aria-hidden="true"></i> If 4 or more friends join Shoolini, you win Amazon vouchers worth 6000 per admission!</p>
                                                </div>



                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>
                        </section>


                        <section className="section service-area bg-gray overflow-hidden ptb_100" id="test">
                            <div className="container">
                                <div className="row justify-content-between">
                                    <div className="col-12  order-2 order-lg-1"  >

                                        <div className="service-text pt-4 pt-lg-0 px-0 px-lg-4">
                                            <h2 className="text-capitalize mb-4" >Terms and Conditions</h2>

                                            <ul className="service-list style-two">


                                                <div className="service-text">
                                                    <p> <i className="fa-solid fa-circle-arrow-right"></i>  It is mandatory to mention your registration number. The reward program is open only to current students/ alumini of Shoolini & SILB.</p>
                                                </div>






                                                <div className="service-text">
                                                    <p> <i className="fa-solid fa-circle-arrow-right"></i> If the referred name is already in our database, the entry will not be eligible for rewards. You will be informed within 24 hours about this.</p>
                                                </div>



                                                <div className="service-text">
                                                    <p> <i className="fa-solid fa-circle-arrow-right"></i> In case of duplication, we follow first come first serve rule.</p>
                                                </div>





                                                <li className="single-service py-2">
                                                    <div className="service-text">
                                                        <p> <b>When will you get the rewards?</b>
                                                            <br />

                                                            <color> <i className="fa fa-check-circle" aria-hidden="true"></i> The reward on paid applications are issued daily!</color><br />
                                                            <color><i className="fa fa-check-circle" aria-hidden="true"></i> Amazon vouchers for admissions are released once your friend completes 1st semester with Shoolini.</color> </p>



                                                    </div>
                                                </li>


                                            </ul>

                                            <br />
                                            <center><a href="https://api.shooliniuniversity.com/buddy?utm_source=Buddy_Referral&utm_medium=Buddy_Referral&utm_campaign=Buddy_Referral" className="button">REFER NOW</a></center>


                                        </div>

                                        <br /><br />

                                        <div className="section-heading text-center">

                                            <p className=" d-sm-block mt-4">Let’s build a stronger Shoolini community together!</p>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>


                    </div>


                    {/* <GalleryView /> */}
                </main>
            </Style>
           


        </>
    )
}

export default Brp