import React, { Component, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import Slider from 'react-slick'
import { Styles } from './common/styles/Companies'
const BASE_URI = 'https://api.shooliniuniversity.com'

const Companies = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,


    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          rows: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          rows: 2,
        },
      },
    ],
  }

  var result = []

  const [data, setData] = useState([])
  useEffect(() => {
    fetch(`${BASE_URI}/GetCompaniesAPI`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        // <-- Specifying the Content-Type
      }),
      body: JSON.stringify({
        auth: 'shoolini@999',
        id: 'all',
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        setData(res)
      })
      .catch((error) => console.error(error))
  }, [])

  result = data?.success?.[0]?.slice(0, 40)
  return (
    <Styles>
      {/* Main Wrapper */}
      {/* Events Area */}
      <section className="event-page-area event-page py-5">
        <div className="container">
          <div  className="sec-title mb-5 text-left">
            <h2 className="font-weight-bold">THINK PLACEMENTS</h2>
            <h4> in 250+ Top Corporates </h4>
          </div>
          <Row>
            <Col lg="12" md="12">
              
              <Slider {...settings}>
                {result?.map((data, idx) => (
                  <div className="team-item" key={idx}>
                    <div
                      // data-aos="fade-up"
                      className="dflex-card"
                    >
                      {/* <img src={process.env.PUBLIC_URL + `/assets/images/${data.personImage}`} alt="" className="" /> */}
                      <img src={data.imageurl} title={data.title} />
                    </div>
                  </div>
                ))}
              </Slider>
            </Col>
          </Row>
        </div>
      </section>
    </Styles>
  )
}

export default Companies
