import React, { useEffect } from 'react';
import Datas from '../data/faq-event/faq-event.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./common/styles/ranking";
import Aos from 'aos';
import 'aos/dist/aos.css';

const Ranking = () => {


 

    useEffect(() => {
        Aos.init({duration:2000});
       }, [])

    useEffect(() => {

        
        const accordionButton = document.querySelectorAll(".accordion-button");
        accordionButton.forEach(button => {
            button.addEventListener("click", () => {
                button.classList.toggle("active");
                const content = button.nextElementSibling;

                if (button.classList.contains("active")) {
                    content.className = "accordion-content show";
                    content.style.maxHeight = content.scrollHeight + "px";
                } else {
                    content.className = "accordion-content";
                    content.style.maxHeight = "0";
                }
            });
        });
    });

  
    return (
        <Styles>
            {/* Faq & Event */}
            <section className="event-faq-area pt-0">
                <Container>
                    <Row>
                    <Col md="12" lg="5">
                        <div data-aos="fade-right" className="sec-title">
                            <h2>Think <br /> Rankings </h2>
                             <h4> Climbing High </h4>
                        </div>
                        <div className="introVDO">
                        <iframe   src="https://www.youtube.com/embed/NdBAPZxr4Pg?controls=0" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
                        </div>
                    </Col>
                     <Col lg="7" md="12">

                     <div className="event-area">
                                <Row>
                                   
                                    <Col md="12">
                                        {
                                            Datas.eventDataList2.map((eventData, i) => (
                                                <div className="event-box d-flex" key={i}>
                                                   <div className="alignLefts_o">
                                                    <div className="rankingYear">
                                                        <p>{eventData.eventDate}</p>
                                                    </div>
                                                    <div className="rankingLogo">
                                                    <img src={process.env.PUBLIC_URL + `/assets/images/${eventData.ranking_logo}`} alt="" className="img-fluid" />
                                                     
                                                    </div>
                                                    </div>
                                                    <div className="alignLefts">      
                                                    <div className="event-details">
                                                        <h6> <Link to="/recognitions"> {eventData.eventTitle}</Link></h6>
                                                       
                                                        <p>{eventData.eventdesc}</p> 
                                                    </div>
                                                    <div className="seeMoreBtn">
                                                        <Link to="/recognitions"> See More </Link>
                                                    </div>
                                                    </div>  
                                                </div>
                                            ))
                                        }
                                    </Col>
                                </Row>
                            </div>

                            <div className="event-area pt-3">
                                <Row>
                                   
                                    <Col md="12">
                                        {
                                            Datas.eventDataList.map((eventData, i) => (
                                                <div className="event-box d-flex" key={i}>
                                                   <div className="alignLefts_o">
                                                    <div className="rankingYear">
                                                        <p>{eventData.eventDate}</p>
                                                    </div>
                                                    <div className="rankingLogo">
                                                    <img src={process.env.PUBLIC_URL + `/assets/images/${eventData.ranking_logo}`} alt="" className="img-fluid" />
                                                     
                                                    </div>
                                                    </div>
                                                    <div className="alignLefts">      
                                                    <div className="event-details">
                                                        <h6> <Link to="/recognitions"> {eventData.eventTitle}</Link></h6>
                                                       
                                                        <p>{eventData.eventdesc}</p> 
                                                    </div>
                                                    <div className="seeMoreBtn">
                                                        <Link to="/recognitions"> See More </Link>
                                                    </div>
                                                    </div>  
                                                </div>
                                            ))
                                        }
                                    </Col>
                                </Row>
                            </div>

                            <div className="event-area mt-3">
                                <Row>
                                   
                                    <Col md="12">
                                        {
                                            Datas.faqDataList.map((eventData, i) => (
                                                <div  className="event-box d-flex" key={i}>
                                                    <div className="alignLefts_o">
                                                    <div className="rankingYear">
                                                        <p>{eventData.eventDate}</p>
                                                    </div>
                                                    <div className="rankingLogo">
                                                    <img src={process.env.PUBLIC_URL + `/assets/images/${eventData.ranking_logo}`} alt="" className="img-fluid" />
                                                    </div>
                                                    </div>

                                                    <div className="alignLefts">  
                                                    <div className="event-details">
                                                        <h6><Link to="/shoolini-breaks-into-league-of-top-100-universities"> {eventData.eventTitle}</Link></h6>
                                                       
                                                        <p>{eventData.eventdesc}</p>
                                                    </div>
                                                    <div className="seeMoreBtn">
                                                        <Link to="/shoolini-breaks-into-league-of-top-100-universities"> See More </Link>
                                                    </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                       
                    </Row>
                </Container>
            </section>
        </Styles>
    ) 
}

export default Ranking
