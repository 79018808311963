import React, { useEffect } from 'react';
import { render } from 'react-dom';
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import Header from './Header';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import { Styles } from "./common/styles/Combine";
import { useState } from "react";
import { Link } from 'react-router-dom';
import Datas from '../data/hero/hero-slider.json';
import Aos from 'aos';
import 'aos/dist/aos.css';
import DialogVideo from './Dialogs/DialogVideo';
import ApplyNow from './Dialogs/ApplyNow';
import VideoPlayer from "react-background-video-player";

const SliderDemo = () => {

    useEffect(() => {
        Aos.init({duration:2000});
       }, [])

 return (

  

    <Styles>

	<section className="hero-slider-area mainHome">
   
    <div className="Apps">
      <VideoPlayer 
        className="video"
        src={
          "assets/video/slidevideo.mp4"
        }
        autoPlay={true}
        muted={true}
      />
      <div className="mainInput">
      {Datas.map((data, i) => (
				<div
					key={i}
					className="slider-content slider-image"
					
				>
					 <div className="slider-table">
                                <div className="slider-tablecell">
                                    <Container>
                                        <Row>
                                            <Col md="12">
                                                <div className={data.uniqClass}>
                                                    <div className="slider-title text-center">
                                                        <p className="text-white"><strong> {data.title} </strong></p> 
                                                    </div>
                                                    <div className="slider-desc text-center">
                                                        <h1 data-aos="fade-up">{data.desc}</h1>
                                                        
                                                        {/* <p className="text-white subtitleTxt"> {data.subtitle} </p> */}
                                                         <p className="text-white subtitleTxt"><strong>  {data.research}</strong> </p>
                                                    </div>
                                                   
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="resrchTxt mt-md-5">
                                               {/* <h5> {data.smallT}  </h5>  */}
                                               {/* <h6>  {data.research} </h6> */}
                                                </div>
                                            </Col>
                                            <Col md="6">
                                            <Row>
                                             <Col md="6">
                                            <div className="slider-btn mt-5">
                                                        
                                                        <ApplyNow />
                                                        <a target="_blank" href="https://admissions.shooliniuniversity.com/?utm_source=organic&utm_medium=website&utm_campaign=applynow" className="slider-btn2" to="">Apply Now</a>
                                              </div> 
                                                </Col>
                                                {/* <Col md="6">
                                                <div className="videoMosdal hero__quote-animation">

                                                    <DialogVideo />
                                                   
                                                    </div>
                                                </Col>    */}

                                            </Row>
                                             
                                         </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </div>
				</div>
			))}
      </div>
    </div>







{/*    
		<Slider className="slider-wrapper">
			{Datas.map((data, i) => (
				<div
					key={i}
					className="slider-content slider-image"
					style={{ background: `url('/assets/images/${data.backgroundImage}') no-repeat center center` }}
				>
					 <div className="slider-table">
                                <div className="slider-tablecell">
                                    <Container>
                                        <Row>
                                            <Col md="12">
                                                <div className={data.uniqClass}>
                                                    <div className="slider-title text-center">
                                                        <p>{data.title}</p> 
                                                    </div>
                                                    <div className="slider-desc text-center">
                                                        <h1 data-aos="fade-up">{data.desc}</h1>
                                                        
                                                        <p className="text-white subtitleTxt"> {data.subtitle} </p>
                                                    </div>
                                                   
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="resrchTxt mt-md-5">
                                               <h5> {data.smallT}  </h5> 
                                               <h6>  {data.research} </h6>
                                                </div>
                                            </Col>
                                            <Col md="6">
                                            <Row>
                                             <Col md="6">
                                            <div className="slider-btn mt-5">
                                                        
                                                        <ApplyNow />
                                                        <a target="_blank" href="https://admissions.shooliniuniversity.com/?utm_source=organic&utm_medium=website&utm_campaign=applynow" className="slider-btn2" to="">Apply Now</a>
                                              </div> 
                                                </Col>
                                                <Col md="6">
                                                <div className="videoMosdal hero__quote-animation">

                                                    <DialogVideo />
                                                   
                                                    </div>
                                                </Col>   

                                            </Row>
                                             
                                         </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </div>
				</div>
			))}

                

		</Slider> */}
	</section>
    </Styles>
 );
            }

export default SliderDemo;
