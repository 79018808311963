import Header from './components/Header'
import React from 'react'
import IconBox from './components/IconBox'
import Footer from './components/Footer'
// import ProgrammeSlider from './components/ProgrammeSlider';
import Ranking from './components/Ranking'
import ShooliniAdvantage from './components/ShooliniAdvantage'
import Programmes from './Pages/School-Programe/Programmes'
import SearchProgram from './components/SearchProgram'
import Testimonials from './components/Testimonials'
import ResearchStories from './components/ResearchStories'
import Events from './Pages/events/Events'
import ShooliniImpact from './components/ShooliniImpact'
import SliderDemo from './components/SliderDemo'
import Companies from './components/Companies'
import GalleryView from './components/GalleryView'
import ScrollButton from './components/common/ScrollButton'
import VIdeoSLide from './components/VIdeoSLide'

const Homepage = () => {
  return (
    <>

      <Header />
      {/* <VIdeoSLide /> */}
      <SliderDemo />
      {/* <HeroSlider /> */}

      <IconBox />
      {/* About Area */}
      {/* <AboutUs />

     {/* Ranking Area */}
      <Ranking />

      {/* Logo Slider */}
      <Companies />

      {/* About Shoolini */}
      <ShooliniAdvantage />

      {/* Programmes List */}
      <Programmes />

      {/* SearchProgram Section */}
      <SearchProgram />

      {/* Think Research Section */}
      <Testimonials />

      {/* Testimonials Section */}
      <ResearchStories />



      {/* Events Section */}
      <Events />


      <GalleryView />

      {/* Impact Section */}
      <ShooliniImpact />

<ScrollButton />
      
      {/* <div className="ht-lg">
      
      </div> */}



{/* {
        "id": "1",
        "backgroundImage": "slider-1.jpg",
        "uniqClass": "slider-box slider-box1",
        "title": "Think future at",
        "desc": "India’s No.1 Research University",
        "btnOneLink": "course-grid",
        "btnTwoLink": "contact",
        "subtitle": "No. 6 in Asia",
        "smallT" : "Research Citations Per Paper",
        "research": "QS World University Rankings | Asia | 2022"
    } */}
    

      <Footer />
    </>
  )
}

export default Homepage
