import styled from "styled-components";
import { colors } from "../elements/elements";

export const Styles = styled.div`
    
        .scrollTOp {
            position: fixed;
            right: 0;
            z-index: 1;
            bottom: 80px;
            cursor: pointer;
            background:#979797 !important;
            display: -webkit-inline-box;
            display: -webkit-inline-flex;
            display: -ms-inline-flexbox;
            display: -webkit-inline-box;
            display: -webkit-inline-flex;
            display: -ms-inline-flexbox;
            display: inline-flex;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
            box-sizing: border-box;
            outline: 0;
            border: 0;
            margin: 0;
            border-radius: 0;
            padding: 0;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            vertical-align: middle;
            -moz-appearance: none;
            -webkit-appearance: none;
            -webkit-text-decoration: none;
            -webkit-text-decoration: none;
            text-decoration: none;
            color: inherit;
            font-family: "Roboto","Helvetica","Arial",sans-serif;
            font-weight: 500;
            font-size:20px;
            line-height: 1.75;
            -webkit-letter-spacing: 0.02857em;
            -moz-letter-spacing: 0.02857em;
            -ms-letter-spacing: 0.02857em;
            letter-spacing: 0.02857em;
            text-transform: uppercase;
            min-height: 36px;
            -webkit-transition: background-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,box-shadow 250ms cubic-bezier(0.4,0,0.2,1) 0ms,border-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms;
            -webkit-transition: background-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,box-shadow 250ms cubic-bezier(0.4,0,0.2,1) 0ms,border-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms;
            transition: background-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,box-shadow 250ms cubic-bezier(0.4,0,0.2,1) 0ms,border-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms;
            border-radius: 50%;
            padding: 0;
            min-width: 0;
            width: 56px;
            height: 56px;
            box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
            color: rgba(0,0,0,0.87);
            color: #fff;
        }
    
`;