import React, { useEffect, useState } from 'react'
import Footer from './Footer'
import Header from './Header'
import ResearchStories from './ResearchStories'
import { red } from '@mui/material/colors'
import CardHeader from '@mui/material/CardHeader'
import Avatar from '@mui/material/Avatar'
import Datas from '../data/school/school.json'
import {
  Container,
  Row,
  Col,
  Card as Cards,
  Card,
  Table,
} from 'react-bootstrap'
import { BreadcrumbBox } from './common/Breadcrumb'
// import { Styles } from "../styles/homepage";
import { Styless, Styles } from './common/styles/FacultyOfAgriculture'
import { Link } from 'react-router-dom'
import CourseSearch from '../Pages/Programmes/CourseSearch'
import PopularProgram from '../Pages/Programmes/PopularProgram'
import SearchProgram from './SearchProgram'
import Companies from './Companies'
import Template from './Template'
import Slider from 'react-slick'
import { resetMetaTags, setMetaTags } from '../helper/setMetaTags'

const BASE_URI = 'https://api.shooliniuniversity.com'

const AcademicsSub = ({ id }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
    return () => {}
  }, [])

  var settings = {
    dots: false,
    infinite: false,
    arrows: true,
    autoplay: false,
    speed: 500,
    autoplaySpeed: 1500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  const [Data, setData] = useState({})
  const [Gallery, setGallery] = useState([])
  const [Numbers, setNumbers] = useState([])
  const [companies, setcompanies] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [showTable, setShowTable] = useState(false)
  const [bgImg, setBgImg] = useState('')
  const [facD, setFacD] = useState([])
  const [facList, setFacList] = useState([])
  const [testimonials, setTestimonials] = useState([])

  useEffect(() => {
    document.body.setAttribute('id', `pageid-${id}`)

    fetch(`${BASE_URI}/GetStreamAPI`, {
      method: 'post',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        auth: 'shoolini@999',
        id: id,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res)
        if (res) {
          setData(res?.success[0])
          setMetaTags(res.success[0])
          setTestimonials(res?.success[0]?.testimonial)
          document.title = `${res?.success[0].title} | Shoolini university`
          let bg = JSON.parse(res?.success[0].slider)
          if (bg.length > 0) {
            setBgImg(bg[0].image_url)
          } else {
            setBgImg(`/assets/images/${Datas[0].backgroundImage}`)
          }
        }
      })
    return () => {
      document.body.removeAttribute('id')
      resetMetaTags()
      document.title = 'Shoolini University'
    }
  }, [id])

  //   return <>test</>

  return (
    <>
      <Styles>
        <Header />

        <>
          <Template title={Data?.title} img={bgImg} />

          <section className="templateOne">
            <Container>
              <Row>
                <Col md="12">
                  {Data?.content && (
                    <div className="sec-para my-4">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: Data?.content,
                        }}
                      ></p>
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
          </section>

          <section className="highLights">
            <div className="container-fluid p-0">
              <div className="row m-0">
                <div className="col-md-4 p-0">
                  <img className="w-100" src="assets/images/baby2.jpg" />
                </div>
                <div className="col-md-8 p-0">
                  <div className="hgContent">
                    <div className="hgContentinner">
                      <div class="sec-title text-left">
                        <h2>HighLights </h2>
                      </div>
                      <div className="newData">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: Data?.heighlights,
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="templateOne ">
            <Container className="py-5">
              <Row>
                <Col md="12">
                  {Data?.why_enroll && (
                    <div className="sec-para mt-5">
                      <div className="whyEnroll mb-4">
                        <h4>Why Enroll?</h4>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: Data?.why_enroll,
                          }}
                        ></p>
                      </div>
                    </div>
                  )}
                </Col>
                <Col md="12">
                  <div className="mb-3">
                    {facD?.length > 0 && (
                      <ul className="row">
                        {facD.map((i, idx) => {
                          return (
                            <Card style={{ width: '25%', marginLeft: '10px' }}>
                              <Link
                                state={{ id: i.id }}
                                to={`/facultyprofile/${i.title
                                  .split(' ')
                                  .join('-')}`}
                                style={{
                                  fontSize: '14px',
                                  color: 'black',
                                  fontWeight: 600,
                                }}
                              >
                                <Card.Img
                                  variant="top"
                                  src={i.imageurl}
                                  className="img-responsive"
                                  // style={{ width: '130px' }}
                                />
                                <Card.Body className="text-center">
                                  <Card.Title>{i.title}</Card.Title>
                                  <p>{i.designation}</p>
                                </Card.Body>
                              </Link>
                            </Card>
                          )
                        })}
                      </ul>
                    )}
                  </div>
                </Col>

                <Col md="12">
                  {Data?.faculty_intro && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: Data?.faculty_intro,
                      }}
                    ></p>
                  )}
                </Col>

                <Col md="12">
                  {Data?.researchpart1 && (
                    <div className="research mb-4">
                      <h4>Research</h4>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: Data?.researchpart1,
                        }}
                      ></p>
                    </div>
                  )}
                </Col>

                <Col md="12">
                  {Data?.gallery && (
                    <div className="gallery">
                      <h5>Gallery</h5>
                      <Row>
                        {Data?.gallery &&
                          Gallery.map((row, idx) => (
                            <Col md={4} sm={12} key={idx}>
                              <figure class="snip1139">
                                <img
                                  src={row?.image_url}
                                  alt="sample3"
                                  style={{ width: '250px' }}
                                />
                                <div class="author">
                                  <h5>{row?.title}</h5>
                                </div>
                              </figure>
                            </Col>
                          ))}
                      </Row>
                    </div>
                  )}
                </Col>

                <Col md="12">
                  {Data?.courses && (
                    <div className="placements mb-4">
                      <div class="sec-title pt-md-0 p-md-5 pb-md-4 text-left color-red">
                        <h2>Programs </h2>
                      </div>
                      <div className="placements-list table-responsive">
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>PROGRAM</th>
                              <th>DURATION</th>
                              <th>ELIGIBILITY</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Data?.courses &&
                              Data?.courses.map((i, idx) => {
                                return (
                                  <tr key={idx}>
                                    <td>
                                      <Link
                                        className="text-dark"
                                        to={`/${i?.slug}`}
                                      >
                                        {' '}
                                        {i.title}{' '}
                                      </Link>{' '}
                                    </td>
                                    <td>{i.duration}</td>
                                    <td>{i.eligibility}</td>
                                  </tr>
                                )
                              })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
          </section>
          <section className="moreFac">
            <Container>
              <Row>
                <Col md="12">
                  <div class="py-4 text-left sec-title color-red">
                    <h2>TOP FACULTY MEMBERS</h2>
                  </div>
                  {Data?.facultylistdetails?.length > 0 && (
                    <div className="row">
                      {/* <button
                        className="btn btn-primary mb-4"
                        onClick={() => setShowTable(!showTable)}
                      >
                        Show Faculty Members
                      </button> */}

                      {facList &&
                        Data.facultylistdetails.map((i, idx) => {
                          return (
                            <Col md="3" xs="6" className="mb-3">
                              <Link
                                to={`/facultyprofile/${i.name
                                  .split(' ')
                                  .join('-')}`}
                                state={{ id: i.id }}
                              >
                                <Card className="facCard  my-2" key={idx}>
                                  <CardHeader
                                    avatar={
                                      <Avatar
                                        sx={{ bgcolor: red[500] }}
                                        aria-label="recipe"
                                      >
                                        <img
                                          src={
                                            i.imageurl
                                              ? i.imageurl
                                              : process.env.PUBLIC_URL +
                                                `/assets/images/instructor-2.jpg`
                                          }
                                          alt=""
                                          className="img-fluid"
                                        />
                                      </Avatar>
                                    }
                                    title={i.name}
                                    subheader={i.designation}
                                  />
                                </Card>
                              </Link>
                            </Col>

                            // <tr key={idx}>
                            //   <td>{i.name}</td>
                            //   <td>{i.designation}</td>
                            //   <td>
                            //     <Link
                            //       to={`/facultyprofile/${i.name
                            //         .split(" ")
                            //         .join("-")}`}
                            //       state={{ id: i.id }}
                            //     >
                            //       View Profile
                            //     </Link>
                            //   </td>
                            // </tr>
                          )
                        })}
                    </div>
                  )}

                  {facList &&
                    facList.map((i, idx) => {
                      return (
                        <Col md="3" xs="6">
                          <Link
                            to={`/facultyprofile/${i.name
                              .split(' ')
                              .join('-')}`}
                            state={{ id: i.id }}
                          >
                            <Card className="facCard my-2" key={idx}>
                              <CardHeader
                                avatar={
                                  <Avatar
                                    sx={{ bgcolor: red[500] }}
                                    aria-label="recipe"
                                  >
                                    <img
                                      src={
                                        i.imageurl
                                          ? i.imageurl
                                          : process.env.PUBLIC_URL +
                                            `/assets/images/instructor-2.jpg`
                                      }
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </Avatar>
                                }
                                title={i.name}
                                subheader={i.designation}
                              />
                            </Card>
                          </Link>
                        </Col>
                      )
                    })}
                </Col>
              </Row>
            </Container>
          </section>
          <section>
            <ResearchStories />
          </section>
          <SearchProgram />
          <Companies />

          {testimonials?.length > 0 && (
            <section className="templateOne templateHeading bg-white py-5">
              <Container>
                <div className=" text-left sec-title color-red">
                  <h2 className=" py-3"> Student Voices</h2>
                </div>

                <Row>
                  <Col md="12">
                    {testimonials && testimonials.length > 0 && (
                      <Slider {...settings}>
                        {testimonials?.map((item, i) => (
                          <div className="userDetailCard" key={i}>
                            <div className="row">
                              <figure className="cj-media-left col-4">
                                <p className="user-image">
                                  <img
                                    src={item?.imageurl}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </p>
                              </figure>
                              <div className="userContent col-8">
                                <h5>{item.name}</h5>
                                <p>{item.content}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </Slider>
                    )}
                  </Col>
                </Row>
              </Container>
            </section>
          )}
        </>

        <Footer />
      </Styles>
    </>
  )
}

export default AcademicsSub
