import React, {useEffect} from 'react'
import HeaderLanding from '../Layout/HeaderLanding'
import Footer from '../../components/Footer';
import { Container, Row, Col, Dropdown } from 'react-bootstrap'
import { Style } from './css/mba';
import Companies from '../../components/Companies';
import Aos from 'aos';
import 'aos/dist/aos.css';
import ResearchStories from '../../components/ResearchStories';
import SearchProgram from '../../components/SearchProgram';
import Testimonials from '../../components/Testimonials';
import { Link } from 'react-router-dom';
import { FiArrowRightCircle } from "react-icons/fi";
import GalleryView from '../../components/GalleryView';

const Mba = () => {

    useEffect(() => {
        Aos.init({duration:2000});
       }, [])


    return (
        <>
            <HeaderLanding />
            <Style>
                <main>
                    <section className="heroImage bg-white">
                        <Container>
                            <Row>
                                <Col md="6">
                                    <div className="hero-text">
                                        <h3> A Top-Tier </h3>
                                        <h1> MBA</h1>
                                        <h5> At India's Top-Ranked University</h5>
                                        <p> The Shoolini MBA prepares you to succeed in the fast-paced, constantly changing business world</p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="py-5">
                        <Container>
                            <Row>
                                <Col md="12">
                                    <div className="titleName text-center py-4">
                                      <h2>  <span>The Ranking</span> that matter </h2>
                                    </div>
                                </Col>

                            <Col md="12">
                                <div className="RankingItem">
                                    <Row>
                                        <Col md="4">
                                            <div className="rCard">
                                                <div className="rCardLeft">
                                                    <h3 className="display-1"> 1 </h3>
                                                </div>
                                                <div className="rCardRight">
                                                    <div className="imgCard">
                                                        <img  src={process.env.PUBLIC_URL + '/assets/images/nirfg.png'} />
                                                    </div>
                                                   
                                                </div>
                                            </div> 
                                        </Col>
                                        <Col md="4">
                                            <div className="rCard">
                                                <div className="rCardLeft">
                                                    <h3 className="display-1"> 7 </h3>
                                                </div>
                                                <div className="rCardRight">
                                                    <div className="imgCard">
                                                        <img  src={process.env.PUBLIC_URL + '/assets/images/nirfg2.png'} />
                                                    </div>
                                                   
                                                </div>
                                            </div> 
                                        </Col>
                                        <Col md="4">
                                            <div className="rCard">
                                                <div className="rCardLeft">
                                                    <h3 className="display-1"> 6 </h3>
                                                </div>
                                                <div className="rCardRight">
                                                    <div className="imgCard">
                                                        <img  src={process.env.PUBLIC_URL + '/assets/images/nirfg3.png'} />
                                                    </div>
                                                    
                                                </div>
                                            </div> 
                                        </Col>
                                        <Col className="p-0 mt-3" md="4">
                                            <div className="rCard">
                                                <div className="rCardLeft">
                                                    <h3 className="display-1"> 6 </h3>
                                                </div>
                                                <div className="rCardRight">
                                                    <div className="imgCard">
                                                        <img  src={process.env.PUBLIC_URL + '/assets/images/nirfg.png'} />
                                                    </div>
                                                    
                                                </div>
                                            </div> 
                                        </Col>
                                        <Col className="p-0 mt-3" md="8">
                                            <div className="rCard inRow">
                                                <div className="rCardLeft">
                                                    <h3 className="display-1"> 76-100 </h3>
                                                </div>
                                                <div className="rCardRight">
                                                    <div className="imgCard">
                                                        <img  src={process.env.PUBLIC_URL + '/assets/images/nirf4.png'} />
                                                    </div>
                                                   
                                                </div>
                                            </div> 
                                        </Col>
                                    </Row>
                                </div>
                            </Col>




                            </Row>
                        </Container>
                    </section>

                    <section className="rankCount">
                        <Container>

                            <Row>

                            <Col md="12">
                                    <div className="titleName text-center py-4 mb-4">
                                      <h2>  <span>The MBA</span> that Prepares <br /> you for the top job <a className="applyBtn" href="">
                                          Apply Now
                                          </a>  </h2>
                                    </div>
                                </Col>

                                <Col md="4">
                                    <div className="count-text">
                                       <h3> 100% </h3>
                                       <h5> Placements </h5>
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="count-text border-lr">
                                       <h3> 250+ </h3>
                                       <h5> Companies </h5>
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="count-text">
                                       <h3> 16L</h3>
                                       <h5> Highest CTC </h5>
                                    </div>
                                </Col>

                              <Col md="12">
                                  <br /> <br />
                              </Col>
                            </Row>
                        </Container>
                    </section>
                
              <section className="compnies">  <Companies />  </section> 
               
                 <section className="stories-area">
                 <Row className="m-0">
                 <Col className="bgImgSU" md="6">
                            <div data-aos="fade-up" className="ml-4s StoriesData">
                                <h4> The MBA that gives you global exposure </h4>
                                <p> Learn how a Shoolini researcher’s innovation to provide clean drinking water is changing the world </p>
                            </div>
                            <Link className="iconArr" to="/"> <FiArrowRightCircle /> </Link>
                        </Col>

                        <Col className="full-wrap-red pl-4s" md="6">
                            <div  className="sec-title z-index-9">
                                <h2 className="text-white">Think Research </h2>
                                <p className="whyUsSubText text-white"> that matches the world’s best universities </p>

                                <ul className="whyUs text-white pl-0">
                                    <li>1000+ Patents Filed </li>
                                    <li> H-index {">"} 86</li>
                                    <li>Field Weighted Citation Impact (FWCI) 2.27 </li>
                                    <li>No.1 In India in Citations Per Paper </li>
                                    <li> 180+ State-of-the-Art Laboratories </li>
                                    <li> 9 centres of excellence </li>
                                    <li> 2 Shoolini researchers among top 1% scientists </li>
                              
                                </ul>
                                <Link className="iconArrL" to="/"> Learn why We are A Top-ranked Research University <FiArrowRightCircle />   </Link>
                            </div>
                        </Col>
                      

                    </Row> 
                     
                </section>   



                <SearchProgram />
                <Testimonials />
                {/* <GalleryView /> */}
                </main>
            </Style>
            <Footer />


        </>
    )
}

export default Mba