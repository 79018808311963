import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/stickyMenu";

const StickyMenu = () => {


  const [Data, setData] = useState([])

  useEffect(() => {
    fetch('https://api.shooliniuniversity.com/GetTopnavAPI', {
      method: 'post',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        auth: 'shoolini@999',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setData(res?.success)
        // console.log(res?.success)
      })
  }, [])


    useEffect(() => {
        window.addEventListener("scroll", () => {
            const stickyMenu = document.querySelector(".sticky-menu");

            if (window.scrollY > 160) {
                stickyMenu.classList.add("sticky");
            } else {
                stickyMenu.classList.remove("sticky");
            }
        });
    });


    return (
        <>
             <Styles>
            {/* Sticky Menu */}
            <section className="sticky-menu">
            <div className="container-fluid pxs-4">
                        <Row>

                        <Col md="2">
                                <div className="logo">
                                    <Link to={process.env.PUBLIC_URL + "/"}><img className="brandLogo" src={process.env.PUBLIC_URL + "/assets/images/logo.png"} alt="" /></Link> 
                                </div>
                            </Col>
                            <Col md="10">
                            <div className="main-menu-box">
                                    <div className="menu-box d-flex justify-content-between">
                                      
                                    <ul className="nav menu-nav">
                      {Data &&
                        Data.map((row, index) => {
                          return row?.sub_menu.length === 0 ? (
                            <li
                              className="nav-item dropdown active"
                              key={index}
                            >
                              {row.text === 'HOME' ? (
                                <Link
                                  to="/"
                                  className="nav-link dropdown-toggle"
                                >
                                  {row.text}
                                </Link>
                              ) : (
                                <Link
                                  to={`/${row.link}`}
                                  className="nav-link dropdown-toggle"
                                  data-toggle="dropdown"
                                >
                                  {row.text}
                                </Link>
                              )}
                            </li>
                          ) : (
                            <li className="nav-item dropdown" key={index}>
                              <Link
                                to={row.link && `/${row.link}`}
                                className="nav-link dropdown-toggle"
                                data-toggle="dropdown"
                              >
                                {row.text} <i className="las la-angle-down"></i>
                              </Link>
                              <ul className="dropdown list-unstyled">
                                {row?.sub_menu.map((sub, i) => {
                                  return (
                                    <li
                                      className="nav-item navItem_unpos"
                                      key={i}
                                    >
                                      {sub.text ===
                                      'All Shoolini Faculty List' ? (
                                        <Link
                                          to={`${sub.link}`}
                                          className="nav-link"
                                        >
                                          {sub.text}
                                          {sub?.sub_menu?.length > 0 && (
                                            <i className="las la-angle-down"></i>
                                          )}
                                        </Link>
                                      ) : (
                                        <Link
                                          to={`/${sub.link}`}
                                          className="nav-link"
                                        >
                                          {sub.text}
                                          {sub?.sub_menu?.length > 0 && (
                                            <i className="las la-angle-down"></i>
                                          )}
                                        </Link>
                                      )}

                                      {sub?.sub_menu?.length > 0 && (
                                        <ul className="dropdown_Last list-unstyled">
                                          {sub.sub_menu.map((submenu, idx) => {
                                            return (
                                              <li
                                                className="nav-item"
                                                key={idx}
                                              >
                                                {submenu.text ===
                                                'Test Page' ? (
                                                  <Link
                                                    to={`${submenu.link}`}
                                                    className="nav-link"
                                                  >
                                                    {submenu.text}
                                                  </Link>
                                                ) : (
                                                  <Link
                                                    to={`/${submenu.link}`}
                                                    className="nav-link"
                                                  >
                                                    {submenu.text}
                                                  </Link>
                                                )}
                                              </li>
                                            )
                                          })}
                                        </ul>
                                      )}
                                    </li>
                                  )
                                })}
                              </ul>
                            </li>
                          )
                        })}

                    
                    </ul>
                    <ul className="nav search-cart-bar">
                    <li className="nav-item side-box">
                                            <div className="apply-btn">
                                                <a href="https://admissions.shooliniuniversity.com/?utm_source=organic&utm_medium=website&utm_campaign=applynow" target="_blank">Apply Now</a>
                                            </div>
                                            </li>
                    </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
            </section>
        </Styles> 
        </>
    )
}

export default StickyMenu
