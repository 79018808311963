import React, { useState } from 'react'
import { Styles } from "../components/common/styles/search";
import { useNavigate } from 'react-router-dom'

const SearchResearcher = () => {
  const history = useNavigate()
  const [searchTerm, setSearchTerm] = useState(null)

  const styles = {
    display: 'inline',
    width: '30%',
    height: 50,
    float: 'left',
    padding: 5,
    border: '0.5px solid black',
    marginBottom: 10,
    marginRight: 10,
  }

  const searchHandler = async (e) => {
    console.log(searchTerm)
    e.preventDefault()
    history('/search', { state: searchTerm })
  }

  return (
    <>
    <Styles> 
      <section className="breadcrumbs">
        <div className="">
          <div className="searchForm">

          <div className="course-search">
                    <h5>Search Program</h5>
                    <form
                className="row"
                id="search-form"
                onSubmit={searchHandler}
                encType="multipart/form-data"
              >
                        <input
                      type="text"
                      className="form-control"
                      onChange={(e) => setSearchTerm(e.target.value)}
                      placeholder="Search eg: bcom"
                    />
                      <button  id="basic-addon2"
                        onClick={searchHandler} type="submit">
                        <i className="las la-search"></i>
                      </button>
                    </form>
                  </div>
 
          </div>
        </div>
      </section>
      </Styles>
    </>
  )
}

export default SearchResearcher
