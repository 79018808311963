import React, { useEffect, Component, useState } from 'react'
import Slider from 'react-slick'
import { Container, Row, Col, Dropdown } from 'react-bootstrap'
import Datas from '../data/Testimonials/Testimonials.json'
import { Styles } from './common/styles/testimonials'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const Testimonials = () => {
  const [Data, setData] = useState([])

  useEffect(() => {
    fetch('https://api.shooliniuniversity.com/GetHprogramAPI', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        auth: 'shoolini@999',
        url: 'homepage',
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data.success[0])
        document.title = `${data.success[0]?.title}`
      })
  }, [])

  var settings = {
    dots: false,
    infinite: true,
    arrows: true,
    autoplay: false,
    speed: 500,
    autoplaySpeed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <Styles>
      <div className="bg-image">
        <section className="testimonials-area">
          <div
            className="testimonialsSection"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${Datas.backgroundImage})`,
            }}
          >
            <Container>
              <Row>
                <Col md="6" sm="5"></Col>
                <Col md="6" sm="7">
                  <div className="mb-md-5 py-5 sec-title text-left">
                    <h2> Students' Voices </h2>
                  </div>
                  <Slider {...settings}>
                    {Data &&
                      Data.testimonial?.map((item, i) => (
                        <div className="team-item" key={i}>
                          <div className="dflex-card">
                            <img src={item?.imageurl} alt="" className="" />

                            {/* <p>{data.personTitle}</p> */}
                          </div>

                          <div className="img-content">
                            <h5>{item.name}</h5>
                            <p>{item.content}</p>
                          </div>
                        </div>
                      ))}
                  </Slider>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
      </div>
    </Styles>
  )
}

export default Testimonials
