import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Style } from './Style/hedearlanding';

const HeaderLanding = () => {
  return (
    <>
    
    <Style> 
        <div className="HeaderLanding"> 
    <nav className="navbar static-top">
      <div className="container">
        <a className="navbar-brand" href="#"><img
                      className="brandLogo"
                      src={process.env.PUBLIC_URL + '/assets/images/f-logo.png'}
                      alt=""
                    /> </a>
        <div id='signin_btn' className="text-white">Think Learning, Think Success</div>
      </div>
    </nav>
    </div>
    </Style>
    


    </>
  )
}

export default HeaderLanding