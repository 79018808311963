import styled from 'styled-components'


export const Styles = styled.div`
  .templateOne, .highLights {
    .section-title h2{
        width: 80%;
        margin: auto;
        font-weight: 700;
    }
    .sec-para {
        background: rgb(255, 255, 255);
        padding: 20px 30px;
        max-width: 1100px;
        margin: auto auto 60px;
        box-shadow: rgb(0 0 0 / 10%) 0px 0px 15px 0px;
    }
    .sec-para2 {
        background: rgb(255, 255, 255);
        max-width: 1100px;
        margin: auto auto 60px;
        box-shadow: rgb(0 0 0 / 10%) 0px 0px 15px 0px;
    }
    .hgContent {
        height: 100%;
        background: #f2072f;
        color: #fff;
        padding: 30px 50px;
        text-align: left;
        display: flex;
        align-items: center;
    }
    .hgContent p {
        border-bottom: 1px solid #fff;
        padding: 11px 0;
        margin: 0;
    }
    .sec-title h2 {
        color: #fff;
    }
    thead {
        background: #f2072f;
        color: #fff;
        font-size: 24px;
    }
    tbody td {
        line-height: 1.6;
    min-width: 301px;
    }
    .text-darks
    {
        color:#333 !important;
    }
    .section-title h2{
        color:#f02f2f;
    }
    .table tbody tr td:nth-child(1) {
        font-weight: 700;
    }
    .placements-list {
        width: 90% !important;
        margin: auto;
    }
  } 
`
