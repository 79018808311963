import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import BreadcrumbBox from '../../components/common/Breadcrumb'
import { Styles } from '../../components/common/styles/header'
import Footer from '../../components/Footer'
import { Styless } from './style/FacultyAll'
import { Container, Row, Col, Card } from 'react-bootstrap'
import CourseSearch from '../Programmes/CourseSearch'
import { Link } from 'react-router-dom'
import { resetMetaTags, setMetaTags } from '../../helper/setMetaTags'

const FacultyAll = ({ id }) => {
  var s = document.createElement('script')
  s.type = 'text/javascript'
  s.async = true
  s.src = 'https://widgets.nopaperforms.com/emwgts.js'
  document.body.appendChild(s)

  const [Data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const [images, setImages] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0)
    try {
      fetch(`https://api.shooliniuniversity.com/FacultyAllAPI`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        }),
        body: JSON.stringify({ auth: 'shoolini@999', id }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            setData(res.success)
            setMetaTags(res.success)
            document.title = `${res?.success.title} | Shoolini University`
          }
        })
      return () => {
        resetMetaTags()
        document.title = `Shoolini University`
      }
    } catch (error) {
      console.error(error)
    }
  }, [id])

  return (
    <>
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper visionmission-page">
          {/* Header 2 */}
          <Header />

          {/* Breadcroumb */}
          <BreadcrumbBox title={Data?.title} />
          <Styless>
            <section className="admidsionProcess my-5 pt-3 ">
              <div className="about__area about__area_one p-relative pt---100 pb---120 mb-5">
                <div className="container">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="section-title titleAdjust">
                        {/* <h4>About Us</h4> */}
                        <h3 className="">{Data?.title}</h3>
                        {/* <p
                          dangerouslySetInnerHTML={{
                            __html: Data?.content,
                          }}
                        ></p> */}

                        <p
                          dangerouslySetInnerHTML={{
                            __html: Data?.content_above,
                          }}
                        ></p>

                        {images.length > 0 && (
                          <Row className="mb-5">
                            {images.map((item, index) => {
                              return (
                                <Col md={6} sm={12}>
                                  <figure class="snip1527">
                                    <div class="image">
                                      <img
                                        className="img-fluid"
                                        src={item.image_url}
                                        alt="pr-sample23"
                                      />
                                    </div>
                                    <figcaption>
                                      <h3> {item?.title} </h3>
                                    </figcaption>
                                  </figure>
                                </Col>
                              )
                            })}
                          </Row>
                        )}

                        {/* <p
                          dangerouslySetInnerHTML={{
                            __html: Data?.content_below_boxes,
                          }}
                        ></p> */}
                      </div>

                      {Data?.facultyall && (
                        <div className="row">
                          {Data?.facultyall?.map((item, index) => {
                            return (
                              <div className="col-md-12" key={index}>
                                <div className="facPage">
                                  <h3>{item?.stream_name}</h3>
                                </div>

                                <div className="row">
                                  {item?.faculty.map((fac, index) => {
                                    return (
                                      <div
                                        className="col-md-3 mb-3"
                                        key={index}
                                      >
                                        {/* <Link
                                          to={`/facultyprofile/${fac.name
                                            .split(' ')
                                            .join('-')}`}
                                          state={{ id: fac.id }}
                                        >
                                          <Card style={{ width: '12rem' }}>
                                            <Card.Img
                                              variant="top"
                                              src={`https://shooliniuniversity.com/${fac.image}`}
                                            />
                                            <Card.Body>
                                              <Card.Title>
                                                {fac?.name}
                                              </Card.Title>
                                              <Card.Text>
                                                {fac?.designation +
                                                  ' ' +
                                                  fac?.description}
                                              </Card.Text>
                                            </Card.Body>
                                          </Card>
                                        </Link> */}

                                        <div className="cnt-block facAll equal-hight">
                                          <figure>
                                            <img
                                              src={`https://api.shooliniuniversity.com/${fac.image}`}
                                              className="img-responsive"
                                              alt=""
                                            />
                                          </figure>
                                          <h3>
                                            <Link
                                              to={`/facultyprofile/${fac.name
                                                .split(' ')
                                                .join('-')}`}
                                              state={{ id: fac.id }}
                                            >
                                              {fac?.name}
                                            </Link>
                                          </h3>

                                          <p>
                                            {' '}
                                            {fac?.designation +
                                              ' ' +
                                              fac?.description}{' '}
                                          </p>
                                        </div>
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      )}
                    </div>

                    <div className="col-md-4">
                      <div className="sidebar-content nnn">
                        <CourseSearch />
                      </div>

                      <div className="sideBarNew">
                        <div className="enqForm">
                          <div
                            className="npf_wgts"
                            data-height="500px"
                            data-w="7d1b402b547afa4d6a10968170a856b6"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <Container>
                <div
                  dangerouslySetInnerHTML={{
                    __html: Data?.youtube,
                  }}
                ></div>
              </Container> */}
            </section>
          </Styless>

          <Footer />
        </div>
      </Styles>
    </>
  )
}

export default FacultyAll
