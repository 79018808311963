import React, { Component, useEffect, useState } from 'react'
import Datas from '../data/shoolini-impact/suImpact.json'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import Slider from 'react-slick'

import { Styles } from './common/styles/impact'

const ShooliniImpact = () => {
  const [newsdata, setNewsdata] = useState([])

  var news_data = []

  useEffect(() => {
    fetch('https://api.shooliniuniversity.com/LatestewsAPI', {
      method: 'post',
      headers: new Headers({
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify({ auth: 'shoolini@999' }),
    })
      .then((response) => response.json())
      .then((res) => setNewsdata(res))
  }, [])

  news_data = newsdata?.success?.slug

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    fade: true,
    speed: 2000,
  }

  const removeHTML = (str) => {
    var tmp = document.createElement('p')
    tmp.innerHTML = str
    return tmp.textContent || tmp.innerText || ''
  }

  return (
    <>
      <Styles>
        <section className="impact-box-area py-5">
          <Container>
            <div data-aos="fade-right" className="sec-title mb-5 text-left">
              <h2>Shoolini Impact </h2>
            </div>
            <Row>
              <Col md="7" lg="7">
                <div className="impactSU">
                  <div className="imSUtext">
                    <Slider {...settings}>
                      {news_data?.map((data, i) => (
                        <div className="team-item" key={i}>
                          <div className="dflex-card fixHeight">
                            <Link to={`news/${data.slug}`}>
                              <img
                                src={data?.imageurl?.split(' ').join(' ')}
                                alt=""
                                className=""
                              />
                            </Link>
                          </div>

                          <div className="IMDetail">
                            <h5>{data.title}</h5>
                            <p className="textLimit3">
                              {' '}
                              {removeHTML(data.content)}{' '}
                            </p>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </Col>
              <Col md="5" lg="5">
                <div className="sidebarText">
                  <ul className="p-0 m-0">
                    {news_data?.map((data, i) => (
                      <li key={i} data-aos="fade-up">
                        <Link to={`news/${data.slug}`}>
                          <div className="listO">
                            <p> {data.title} </p>
                            <img
                              src={data?.imageurl?.split(' ').join(' ')}
                              alt=""
                              className=""
                            />
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                  <div className="moreNews">
                    {/* <Link to="/news"> Explore More News</Link> */}
                     <Link target="_blank" to="https://shooliniuniversity.com/blog/category/latest-news/"> Explore More News</Link> 
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>
    </>
  )
}

export default ShooliniImpact
