import styled from "styled-components";
export const Style = styled.div`
 .HeaderLanding { 
    background: #e33535;
.brandLogo
{
    max-width:150px;
}

}
`;