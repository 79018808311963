import React, { useEffect, useState } from 'react'
import Header from './Header'
import { Styles } from './common/styles/search'
import Footer from './Footer'
import SearchResearcher from './SearchResearcher'
import { Link, Redirect, useLocation } from 'react-router-dom'
import axios from 'axios'
import BreadcrumbBox from './common/Breadcrumb'
import { Container, Row, Col, Form } from 'react-bootstrap'

const BaseURL = process.env.REACT_APP_BASE_URI

const SearchResult = () => {
  const [searchResults, setSearchResults] = useState([])
  const [searchTerm, setSearchTerm] = useState(null)
  const location = useLocation()

  const [searchPages, setSearchPages] = useState(null)
  const [searchPageResults, setSearchPageResults] = useState([])

  const searchHandler = async (e) => {
    if (e) e.preventDefault()
    // setSearchResults([]);
    if (!searchTerm) return
    let { data } = await axios.post( 
      `https://api.shooliniuniversity.com/SearchAPI`,
      {
        auth: 'shoolini@999',
        keywords: searchTerm,
      }
    )
    setSearchResults([data.success, searchTerm])
  }

  const searchPage = async (e) => {
    if (e) e.preventDefault()
    // setSearchResults([]);
    if (!searchPages) return
    let { data } = await axios.post(
      `https://api.shooliniuniversity.com/SearchAPI`,
      {
        auth: 'shoolini@999',
        keywords: searchPages,
      }
    )
    setSearchPageResults([data.success, searchPages])
  }

  useEffect(() => {
    if (location?.state) {
      fetch(`https://api.shooliniuniversity.com/SearchAPI`, {
        method: 'post',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
          auth: 'shoolini@999',
          keywords: location?.state,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setSearchResults([data.success, location?.state])
        })
    }
  }, [location.state])

  useEffect(() => {
    if (location?.state) {
      fetch(`https://api.shooliniuniversity.com/PageSearchAPI`, {
        method: 'post',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
          auth: 'shoolini@999',
          keywords: location?.state,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setSearchPageResults([data.success, location?.state])
        })
    }
  }, [location.state])

  var s = document.createElement('script')
  s.type = 'text/javascript'
  s.async = true
  s.src = 'https://widgets.nopaperforms.com/emwgts.js'
  document.body.appendChild(s)

  return (
    <>
      <Styles>
        <Header />
        <BreadcrumbBox />

        <Container className="p-3">
          <div className="row searchedCourse mainContent">
            <div className="col-md-8 pt-2">
              {searchResults.length > 0 && (
                <div className="py-2">
                  {searchResults[1] && (
                    <div class="section-title">
                      <h4> Search Results for </h4>
                      <h3>{searchResults[1]}</h3>
                    </div>
                  )}
                 
                </div>
              )}

              {searchPageResults[0]?.length > 0 ? (
                <div className="py-2">
                  <div className="row">
                    {searchPageResults[0].map((i, idx) => {
                      return (
                        <div className="col-md-6" key={idx}>
                          <div className="cuCourse">
                            <Link to={`/${i.url}`} className="detailView">
                              <div>
                                {' '}
                                <h3> {i.title} </h3>
                                <p>{i.excerpt}</p>
                                {/* <p> <strong> Eligibility </strong>{data?.eligibility} </p>
                            <span> <strong> Duration </strong>{data?.duration} </span> */}
                              </div>
                            </Link>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              ) : (
                <h1>no data found </h1>
              )}
            </div>

            <Col className="mt-md-4 pt-2" md="4">
              <div className="sidebar-content pt-md-2">
                <SearchResearcher />
                

                <div className="sideBarNew mt-3">
                  <div className="enqForm">
                    <div
                      className="npf_wgts"
                      data-height="500px"
                      data-w="7d1b402b547afa4d6a10968170a856b6"
                    ></div>
                  </div>
                </div>
              </div>
            </Col>
          </div>
        </Container>
        <Footer />
      </Styles>
    </>
  )
}

export default SearchResult
