import React, { Component, useState, useEffect } from 'react'
import { Styles } from '../components/common/styles/footer'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Form } from 'react-bootstrap'
import { FaAngleDown } from "react-icons/fa";


const Footer = () => {
  const [pgdata, setPgata] = useState([])

  const [isActive, setActive] = useState(false);
  const [isActive2, setActive2] = useState(false);
  const [isActive3, setActive3] = useState(false);


  const toggleClass = () => {
    setActive(!isActive);
  };
  const toggleClass2 = () => {
    setActive2(!isActive2);
  };
  const toggleClass3 = () => {
    setActive3(!isActive3);
  };

 

  var footerOne = []
  var footerTwo = []
  var footerThree = []

  useEffect(() => {
    fetch('https://api.shooliniuniversity.com/GetFooterAPI', {
      method: 'post',
      headers: new Headers({
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify({ auth: 'shoolini@999' }),
    })
      .then((response) => response.json())
      .then((res) => setPgata(res))
  }, [])

  footerOne = pgdata?.success?.footer_nav1
  footerTwo = pgdata?.success?.footer_nav2
  footerThree = pgdata?.success?.footer_nav3
  // console.log(pgdata)
  // const [open, setOpen] = useState(false);

  return (
    <>
      <Styles>
        <footer className="footer1">
          <div className="container-fluid ">
            <div className="row">
              <div className="col-md-3 bg-reds">
                <div className="footerAbout text-center">
                 <Link to="/">  <img
                    src="../assets/images/footer-logo.png"
                    className="logo-footer"
                  />
                  </Link>
                  <h5 className="text-white">
                    {' '}
                    Think Learning. Think Success.{' '}
                  </h5>
                  <ul className="social list-unstyled list-inline">
                    <li className="list-inline-item">
                      <a target="_blank" href="https://www.facebook.com/ShooliniUniversityOfficial">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a target="_blank" href="https://twitter.com/ShooliniUniv">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a target="_blank" href="https://www.linkedin.com/school/shooliniuniversity/">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li> 
                    <li className="list-inline-item">
                      <a target="_blank" href="https://www.youtube.com/channel/UCIIk_Yyimb_MwWNMr8JEDJg">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a target="_blank" href="https://www.instagram.com/shooliniuniversity/">
                      <i class="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a target="_blank" href="https://api.whatsapp.com/send?phone=917807899735&text=Hi%20Shoolini%20University">
                      <i class="fab fa-whatsapp"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-9">
                <div className="pt-5 leftSideFtr">
                  <div className="row">
                    <div className="col-md-3">
                      <h6 className="ftr_tgl"  onClick={toggleClass} >Quick Links <FaAngleDown /></h6>

                      <ul className={isActive ? 'footer_show': 'footer_hide'}>
                        {footerTwo?.map((data, index) => (
                          <li key={index}>
                            {data.link.indexOf('http') > -1 ? (
                              <a target="_blank" href={`https://shooliniuniversity.com/${data?.link}`} className="detailView">
                                <div key={index} value={data?.id}>
                                  {' '}
                                  <span> {data?.text} </span>
                                </div>
                              </a>
                            ) : (
                              <Link
                                to={`/${data?.link}`}
                                className="detailView"
                              >
                                <div key={index} value={data?.id}>
                                  {' '}
                                  <span> {data?.text} </span>
                                </div>
                              </Link>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="col-md-3">
                      <h6 className="ftr_tgl"  onClick={toggleClass2}> Information About <FaAngleDown /></h6>
                      <ul className={isActive2 ? 'footer_show': 'footer_hide2'}>
                        {footerOne?.map((data, index) => (
                          <li key={index}>
                            {/* <Link to={`/${data?.link}`} className="detailView">
                              <div key={index} value={data?.id}>
                                {' '}
                                <span> {data?.text} </span>
                              </div>
                            </Link> */}
                            {data.link.indexOf('http') > -1 ? (
                              <a href={`${data?.link}`} className="detailView">
                                <div key={index} value={data?.id}>
                                  {' '}
                                  <span> {data?.text} </span>
                                </div>
                              </a>
                            ) : (
                              <Link
                                to={`/${data?.link}`}
                                className="detailView"
                              >
                                <div key={index} value={data?.id}>
                                  {' '}
                                  <span> {data?.text} </span>
                                </div>
                              </Link>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="col-md-3">
                      <h6 className="ftr_tgl" onClick={toggleClass3}> Information For <FaAngleDown /></h6>
                      <ul className={isActive3 ? 'footer_show': 'footer_hide3'}>
                        {footerThree?.map((data, index) => (
                          <li key={index}>
                            {/* <Link
                              to={{
                                pathname: `${data?.link}`,
                              }}
                              className="detailView"
                            >
                              <div key={index} value={data?.id}>
                                {' '}
                                <span> {data?.text} </span>
                              </div>
                            </Link> */}
                            {data.link.indexOf('https') > -1 ? (
                              <a href={`${data?.link}`} className="detailView">
                                <div key={index} value={data?.id}>
                                  {' '}
                                  <span> {data?.text} </span>
                                </div>
                              </a>
                            ) : (
                              <Link
                                to={`/${data?.link}`}
                                className="detailView"
                              >
                                <div key={index} value={data?.id}>
                                  {' '}
                                  <span> {data?.text} </span>
                                </div>
                              </Link>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div className="col-md-3">
                      <h6 className="ftr_tgl mb-2"   > Contact </h6>
                      <div> 
                      <p>
                        <strong> City Office </strong> <br />
                        <span>
                          {' '}
                          {/* SILB, The Mall, Solan - 173212 Himachal Pradesh{' '} */}
                          {pgdata?.success?.cityaddress}
                        </span>
                      </p>
                      <p>
                        <strong> Address </strong>
                        <br />
                        <span>
                          {' '}
                          {/* Shoolini University, Kasauli Hills, Solan, Himachal
                          Pradesh. 173229 */}
                          {pgdata?.success?.mainaddress}
                        </span>
                      </p>

                      <p>
                        <strong> Mailing Address: </strong> <br />
                        <span>
                          {' '}
                          {/* Accounts/ Fee: 01792-352002, +91 9736745800{' '} */}
                          {pgdata?.success?.mailingaddress}
                        </span>{' '}
                        <br />
                        {/* <span> Reception: 01792-350000/ 7807899712 </span> */}
                      </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </Styles>
    </>
  )
}

export default Footer
