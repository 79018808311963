import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import BreadcrumbBox from '../../components/common/Breadcrumb'
import { Styles } from '../../components/common/styles/header'
import Footer from '../../components/Footer'
import { Styless } from './style/news'
import { Container, Row, Col, Card } from 'react-bootstrap'
import SearchResearcher from '../../components/SearchResearcher'
import { useParams } from 'react-router-dom'

const NewsTemplate = () => {
  //   var s = document.createElement('script')
  //   s.type = 'text/javascript'
  //   s.async = true
  //   s.src = 'https://widgets.nopaperforms.com/emwgts.js'
  //   document.body.appendChild(s)
  const params = useParams()
  const [news, setNews] = useState({})
  const [Data, setData] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
    try {
      fetch(`https://api.shooliniuniversity.com/NewsAPI`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        }),
        body: JSON.stringify({ auth: 'shoolini@999', slug: params?.slug }),
      })
        .then((res) => res.json())
        .then((res) => {
          console.log(res.success[0])
          if (res.success.length > 0) {
            setNews(res.success[0])
            console.log(res.success[0])
          }
          document.title = `News | Shoolini University`
        })

      return () => {
        document.title = `Shoolini University`
      }
    } catch (error) {
      console.error(error)
    }
  }, [])

  return (
    <>
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper visionmission-page">
          {/* Header 2 */}
          <Header />

          {/* Breadcroumb */}
          <BreadcrumbBox title={news?.title} />
          <Styless>
            <section className="admidsionProcess my-5 pt-3 ">
              <div className="about__area about__area_one p-relative pt---100 pb---120 mb-5">
                <div className="container">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="section-title olList titleAdjust tables">
                        {/* <h4>About Us</h4> */}
                        <h3>{news?.title}</h3>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: news?.excerpt,
                          }}
                        ></p>

                        <Row>
                          <Col md={12} sm={12}>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: news?.content,
                              }}
                            ></p>
                          </Col>
                        </Row>

                        {/* {images.length > 0 && (
                          <Row>
                            {images.map((item, index) => {
                              return (
                                <Col md={6} sm={12}>
                                  <figure class="snip1527">
                                    <div class="image">
                                      <img
                                        className="img-fluid"
                                        src={item.image_url}
                                        alt="pr-sample23"
                                      />
                                    </div>
                                    <figcaption>
                                      <h3> {item?.title} </h3>
                                    </figcaption>
                                  </figure>
                                </Col>
                              )
                            })}
                          </Row>
                        )} */}

                        {/* <p
                          dangerouslySetInnerHTML={{
                            __html: Data?.content_below_boxes,
                          }}
                        ></p> */}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="sidebar-content nnn">
                        <SearchResearcher />
                      </div>

                      <div className="sideBarNew">
                        <div className="enqForm">
                          <div
                            className="npf_wgts"
                            data-height="500px"
                            data-w="7d1b402b547afa4d6a10968170a856b6"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <Container>
                <div
                  dangerouslySetInnerHTML={{
                    __html: Data?.youtube,
                  }}
                ></div>
              </Container> */}
            </section>
          </Styless>

          <Footer />
        </div>
      </Styles>
    </>
  )
}

export default NewsTemplate
