import React, { useEffect, useState } from 'react'
import Footer from './Footer'
import Header from './Header'
import Slider from 'react-slick'
import CardHeader from '@mui/material/CardHeader'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Avatar from '@mui/material/Avatar'
import { red } from '@mui/material/colors'
import Datas from '../data/school/school.json'
import {
  Container,
  Row,
  Col,
  Card as Cards,
  Card,
  Table,
} from 'react-bootstrap'
import { BreadcrumbBox } from './common/Breadcrumb'
// import { Styles } from "../styles/homepage";
import { Styless, Styles } from './common/styles/FacultyOfAgriculture'
import { Link } from 'react-router-dom'
import Template from './Template'
import { resetMetaTags, setMetaTags } from '../helper/setMetaTags'

const BASE_URI = 'https://api.shooliniuniversity.com'

const Academics = ({ id }) => {
  const [Data, setData] = useState({})
  const [Gallery, setGallery] = useState([])
  const [Numbers, setNumbers] = useState([])
  const [companies, setcompanies] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [bgImg, setBgImg] = useState('')
  const [showTable, setShowTable] = useState(false)
  const [facD, setFacD] = useState([])
  const [facList, setFacList] = useState([])
  const [testimonials, setTestimonials] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0)
    return () => {}
  }, [])

  useEffect(() => {
    document.body.setAttribute('id', `pageId-${id}`)
    fetch(`${BASE_URI}/Stream2API`, {
      method: 'post',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        auth: 'shoolini@999',
        id: id,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res) {
          console.log(res)
          setMetaTags(res.success[0])

          setData(res?.success[0])
          if (res?.success[0]?.gallery) {
            setGallery(JSON.parse(res?.success[0]?.gallery))
          }
          if (res?.success[0]?.numbers) {
            setNumbers(JSON.parse(res?.success[0]?.numbers))
          }
          let bg = JSON.parse(res?.success[0].slides)
          if (bg.length > 0) {
            setBgImg(bg[0].image_url)
          } else {
            setBgImg(`/assets/images/${Datas[0].backgroundImage}`)
          }
          setcompanies(res?.success.companydetail)
          setFacD(res?.success.facultydetail)
          setFacList(res?.success.facultylistdetails)
          setTestimonials(res?.success.testimonialdetails)
          setIsLoading(false)
        }
      })
    return () => {
      document.title = `Shoolini University`
      resetMetaTags()
      document.body.removeAttribute('id')
    }
  }, [id])

  var settings = {
    dots: false,
    infinite: false,
    arrows: true,
    autoplay: false,
    speed: 500,
    autoplaySpeed: 1500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <>
      <Styles>
        <Header />

        <>
          <Template title={Data?.title} img={bgImg} />

          <section className="templateOne templateHeading py-5 pt-0">
            <Container>
              <Row>
                <Col md="12">
                  <div className="sec-para">
                    {
                      <p
                        dangerouslySetInnerHTML={{
                          __html: Data?.content,
                        }}
                      ></p>
                    }
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="templateHeading highLights">
            <div className="container-fluid p-0">
              <div className="row m-0">
                <div className="col-md-4 p-0">
                  <img className="w-100" src="assets/images/baby2.jpg" />
                </div>
                <div className="col-md-8 p-0">
                  <div className="hgContent">
                    <div className="hgContentinner">
                      <div class="sec-title text-left">
                        <h2>HighLights </h2>
                      </div>
                      <div className="newData">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: Data?.heighlights,
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="templateHeading bg-white py-5">
            <Container>
              {Gallery.length > 0 && (
                <div className="gallery">
                  <Row>
                    {Gallery.length > 0 &&
                      Gallery.map((row, idx) => (
                        <Col md={4} sm={6} key={idx}>
                          <figure class="snip1527">
                            <div class="image">
                              <img
                                className="img-fluid"
                                src={row?.image_url}
                                alt={row?.title}
                              />
                            </div>
                            <figcaption>
                              <h3> {row?.title} </h3>
                            </figcaption>
                          </figure>
                        </Col>
                      ))}
                  </Row>
                </div>
              )}
            </Container>
          </section>

          <section className="templateHeading templateOne py-4">
            <Container>
              <Row>
                <Col md="12">
                  <div className="sec-Enroll mt-0">
                    {Data?.why_enroll && (
                      <div className="whyEnroll mb-4">
                        <div class=" text-left sec-title color-red">
                          <h2 className="pb-4 pt-md-3">Why Enrol? </h2>
                        </div>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: Data?.why_enroll,
                          }}
                        ></p>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="templateOne templateHeading bg-white py-5">
            <Container>
              <Row>
                <Col md="12">
                  <div className="mb-3">
                    {facD.length > 0 && (
                      <div className="row">
                        <Col md="12">
                          <div className=" text-left sec-title color-red">
                            <h2 className="text-dark pb-2"> Faculty</h2>
                          </div>

                          <div className="newFac">
                            {Data?.faculty_intro && (
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: Data?.faculty_intro,
                                }}
                              ></p>
                            )}
                            {/* 
                            <button
                              className="btn mt-3 btn-primary mb-4"
                              onClick={() => setShowTable (!showTable)}
                            >
                              Show Faculty Members
                            </button> */}
                          </div>
                        </Col>
                        <Col md="12">
                          <Row>
                            {facD.map((i, idx) => {
                              return (
                                <Col className="col-6 my-2" md="3" key={idx}>
                                  <Card>
                                    <Link
                                      state={{ id: i.id }}
                                      to={`/facultyprofile/${i.title
                                        .split(' ')
                                        .join('-')}`}
                                      style={{
                                        fontSize: '14px',
                                        color: 'black',
                                        fontWeight: 600,
                                      }}
                                    >
                                      <Card.Img
                                        variant="top"
                                        src={i.imageurl}
                                        className="img-responsive"
                                        // style={{ width: '130px' }}
                                      />
                                      <Card.Body className="text-center">
                                        <h5 className="proTitle">
                                          {' '}
                                          {i.title}{' '}
                                        </h5>
                                        <p className="font-300">
                                          {i.designation}
                                        </p>
                                      </Card.Body>
                                    </Link>
                                    <div className="facDetail">
                                      <p> {i.intro} </p>
                                    </div>
                                  </Card>
                                </Col>
                              )
                            })}
                          </Row>
                        </Col>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section
            className="bgClr-light templateOne facultyProfile templateHeading py-5"
            style={{ display: `${!showTable ? 'block' : 'block'}` }}
          >
            <Container>
              <div class=" text-left sec-title color-red">
                <h2>Faculty Members </h2>
              </div>
              <Row>
                {/* faculty list  */}

                {facList &&
                  facList.map((i, idx) => {
                    return (
                      <Col md="3" xs="6" key={idx}>
                        <Link
                          to={`/facultyprofile/${i.name.split(' ').join('-')}`}
                          state={{ id: i.id }}
                        >
                          <Card className="facCard my-2" key={idx}>
                            <CardHeader
                              avatar={
                                <Avatar
                                  sx={{ bgcolor: red[500] }}
                                  aria-label="recipe"
                                >
                                  <img
                                    src={
                                      i.imageurl
                                        ? i.imageurl
                                        : process.env.PUBLIC_URL +
                                          `/assets/images/instructor-2.jpg`
                                    }
                                    alt=""
                                    className="img-fluid"
                                  />
                                </Avatar>
                              }
                              title={i.name}
                              subheader={i.designation}
                            />
                          </Card>
                        </Link>
                      </Col>
                    )
                  })}
              </Row>
            </Container>
          </section>

          <section className="templateOne bgSearch templateHeading py-4">
            <Container>
              <Row>
                <Col md="12">
                  {Data?.researchpart1 && (
                    <div className="research mb-4">
                      <div className=" text-left sec-title">
                        <h2 className="text-white py-3"> Research</h2>
                      </div>

                      <p
                        dangerouslySetInnerHTML={{
                          __html: Data?.researchpart1,
                        }}
                      ></p>
                    </div>
                  )}
                </Col>
                <Col md="12">
                  {Data?.researchpart2 && (
                    <div className="research mb-4">
                      {/* <div className=" text-left sec-title"> 
                        <h2 className="text-white py-3"> Research</h2>
                           </div> */}

                      <p
                        dangerouslySetInnerHTML={{
                          __html: Data?.researchpart2,
                        }}
                      ></p>
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
          </section>

          <section className="templateOne templateHeading py-5 bg-white">
            <Container>
              <Row>
                <Col md="12">
                  {Data?.placement && (
                    <div className="placements mb-4">
                      <div className=" text-left sec-title color-red">
                        <h2 className=" py-3"> Placements</h2>
                      </div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: Data?.placement,
                        }}
                      ></p>
                    </div>
                  )}
                </Col>
                {companies.length > 0 && (
                  <div className="placements mb-4">
                    <div className="comapnies-cards">
                      <Row>
                        {companies &&
                          companies.map((com, idx) => {
                            return (
                              <Col
                                lg={2}
                                md={2}
                                sm={2}
                                className="mb-2 col-4"
                                key={idx}
                              >
                                <Card>
                                  <Card.Img
                                    variant="top"
                                    title={com?.title}
                                    alt={com?.title}
                                    src={com?.imageurl}
                                  />
                                </Card>
                              </Col>
                            )
                          })}
                      </Row>
                    </div>
                  </div>
                )}
              </Row>
            </Container>
          </section>

          <section className="templateHeading py-5">
            <Container>
              {Numbers.length > 0 && (
                <div className="gallery mb-4">
                  <div className=" text-left sec-title color-red">
                    <h2 className=" py-3"> Gallery</h2>
                  </div>
                  <Row>
                    {Numbers.length > 0 &&
                      Numbers.map((row, idx) => (
                        <Col md={3} sm={6} key={idx}>
                          <figure class="snip1527">
                            <div class="image">
                              <img
                                className="img-fluid"
                                src={row?.image_url}
                                alt={row?.content}
                              />
                            </div>
                            <figcaption>
                              <h3> {row?.content} </h3>
                            </figcaption>
                          </figure>
                        </Col>
                      ))}
                  </Row>
                </div>
              )}
            </Container>
          </section>

          {testimonials?.length > 0 && (
            <section className="templateOne templateHeading bg-white py-5">
              <Container>
                <div className=" text-left sec-title color-red">
                  <h2 className=" py-3"> Student Voices</h2>
                </div>

                <Row>
                  <Col md="12">
                    {testimonials && testimonials.length > 0 && (
                      <Slider {...settings}>
                        {testimonials?.map((item, i) => (
                          <div className="userDetailCard" key={i}>
                            <div className="row">
                              <figure className="cj-media-left col-4">
                                <p className="user-image">
                                  <img
                                    src={item?.imageurl}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </p>
                              </figure>
                              <div className="userContent col-8">
                                <h5>{item.name}</h5>
                                <p>{item.content}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </Slider>
                    )}
                  </Col>
                </Row>
              </Container>
            </section>
          )}
        </>

        <Footer />
      </Styles>
    </>
  )
}

export default Academics
