import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Dropdown } from 'react-bootstrap'
import { Styles } from './styles/mobilemenu'
import { useNavigate } from 'react-router-dom'

const MobileMenu = () => {
  const [visible, setVisible] = useState(false)
  const [opened, setOpened] = useState(false)
  const [Data, setData] = useState([])
  const [openSubMenu, setOpenSubMenu] = useState(false)

  useEffect(() => {
    fetch('https://api.shooliniuniversity.com/GetTopnavAPI', {
      method: 'post',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        auth: 'shoolini@999',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setData(res?.success)
        // console.log(res?.success)
      })
  }, [])

  // Mobile Menu
  const hmBtn = document.getElementById('mb-sidebar-btn')

  if (hmBtn) {
    const mdSidebarOverlay = document.getElementById('mb-sidebar-overlay')
    const mdSidebarBody = document.getElementById('mb-sidebar-body')
    const mdSidebarExit = document.getElementById('close-mb-sidebar')

    hmBtn.addEventListener('click', function (e) {
      e.preventDefault()
      // mdSidebarOverlay.classList.toggle('visible')
      // mdSidebarBody.classList.toggle('opened')
    })

    // mdSidebarOverlay.addEventListener('click', function (e) {
    //   e.preventDefault()
    //   mdSidebarOverlay.classList.remove('visible')
    //   mdSidebarBody.classList.remove('opened')
    // })

    // mdSidebarExit.addEventListener('click', function (e) {
    //   e.preventDefault()
    //   mdSidebarOverlay.classList.remove('visible')
    //   mdSidebarBody.classList.remove('opened')
    // })
  }

  // Menu Accordion -----------------
  const menuButton = document.querySelectorAll('.mb-menu-button')
  menuButton.forEach((button) => {
    button.addEventListener('click', () => {
      button.classList.toggle('active')
      const content = button.nextElementSibling

      if (button.classList.contains('active')) {
        content.className = 'mb-menu-content show'
        content.style.maxHeight = content.scrollHeight + 'px'
      } else {
        content.className = 'mb-menu-content'
        content.style.maxHeight = '0'
      }
    })
  })

  const toggleMenu = () => {
    setVisible(!visible)
    setOpened(!opened)
  }

  const history = useNavigate()
  const [searchTerm, setSearchTerm] = useState(null)

  const searchHandler = async (e) => {
    console.log(searchTerm)
    e.preventDefault()
    history('/search', { state: searchTerm })
  }

  return (
    <>
      <Styles>
        {/* Mobile Menu */}
        <section className="mobile-menu-area">
          <Container>
            <Row>
              <Col md="0" sm="12">
                <div className="mb-topbar d-flex justify-content-between">
                  <div className="topbar-item">
                    <p>
                      <i className="las la-phone"></i>+1 (396) 486 4709
                    </p>
                  </div>
                  <div className="topbar-item">
                    <ul className="list-unstyled list-inline">
                      <li className="list-inline-item">
                        <Link to={process.env.PUBLIC_URL + '/login'}>
                          Log In
                        </Link>
                      </li>
                      <li className="list-inline-item">/</li>
                      <li className="list-inline-item">
                        <Link to={process.env.PUBLIC_URL + '/registration'}>
                          Register
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mb-logo-area d-flex justify-content-between">
                  <div className="mb-logo-box d-flex">
                    <div className="hm-button" onClick={toggleMenu}>
                      <a
                        href={process.env.PUBLIC_URL + '/'}
                        id="mb-sidebar-btn"
                      >
                        <i className="las la-bars"></i>
                      </a>
                    </div>

                    <div className="mb-logo">
                      <Link to={process.env.PUBLIC_URL + '/'}>
                        <img
                          src={
                            process.env.PUBLIC_URL + '/assets/images/f-logo.png'
                          }
                          alt=""
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="mb-search-box">
                    <form action="#">
                      <input
                        type="text"
                        name="search"
                        onChange={(e) => setSearchTerm(e.target.value)}
                        autoComplete="off"
                        placeholder="Search Here"
                      />
                      <button onClick={searchHandler} type="submit">
                        <i className="las la-search"></i>
                      </button>
                    </form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Mobile Menu Sidebar */}
        <section
          className={`mb-sidebar ${opened ? 'opened' : ''}`}
          id="mb-sidebar-body"
        >
          <div className="mb-sidebar-heading d-flex justify-content-between">
            <div>
              <h5>Menu</h5>
            </div>
            <div></div>
          </div>
          <div className="mb-sidebar-menu">
            {Data &&
              Data.map((item, index) => {
                return (
                  <div className="mb-menu-item" key={index}>
                    <button className="mb-menu-button active">
                      {item.sub_menu.length > 0 ? (
                        <p>
                          {item.text}
                          <i className="las la-plus"></i>
                        </p>
                      ) : (
                        <>
                          {item.text === 'HOME' ? (
                            <Link to="/">{item.text}</Link>
                          ) : (
                            <Link to={`/${item.link}`}>{item.text}</Link>
                          )}
                        </>
                      )}
                    </button>
                    {item.sub_menu.length > 0 ? (
                      <div className="mb-menu-content">
                        <ul className="list-unstyled">
                          {item.sub_menu.map((row, sub_index) => {
                            return (
                              <li className="nav-item" key={sub_index}>
                                <Link to={`/${row.link}`}>
                                  {row.text}
                                  {row?.sub_menu?.length > 0 && (
                                    <i
                                      className="las la-plus "
                                      style={{ paddingLeft: '50px' }}
                                      onClick={() =>
                                        setOpenSubMenu(!openSubMenu)
                                      }
                                    ></i>
                                  )}
                                </Link>
                                <ul
                                  className={`mb-menu-content list-unstyled ${
                                    openSubMenu && 'show'
                                  }`}
                                >
                                  {row?.sub_menu &&
                                    row?.sub_menu.map(
                                      (sub_row, sub_sub_index) => {
                                        return (
                                          <li
                                            className="nav-item"
                                            key={sub_sub_index}
                                          >
                                            <Link to={`/${sub_row.link}`}>
                                              <span
                                                style={{ paddingLeft: '20px' }}
                                              >
                                                {sub_row.text}
                                              </span>
                                            </Link>
                                          </li>
                                        )
                                      }
                                    )}
                                </ul>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                )
              })}

            <div className="mb-menu-item">
              <button className="mb-menu-button active">
                <p>SU Students </p>
              </button>
              <div className="mb-menu-content show">
                <ul className="list-unstyled">

                <li className="list-inline-item">
                     <Link target="_blank" to="/blog"> 
                     Blogs
                        </Link>
                    </li>

                  <li className="list-inline-item">
                    <Link to="/Internship">Internship</Link>
                  </li>

                  <li className="list-item">
                    <a
                      target="_blank"
                      href="https://my.shooliniuniversity.com/"
                    >
                      SU Students
                    </a>
                  </li>
                  <li className="list-item">
                    <a
                      target="_blank"
                      href="https://alumni.shooliniuniversity.com/"
                    >
                      Alumni
                    </a>
                  </li>
                  <li className="list-item">
                    <a
                      target="_blank"
                      href="/national-academic-depository"
                    >
                      UGC-NAD
                    </a>
                  </li>
                  <li className="list-item">
                    <Dropdown>
                      <Dropdown.Toggle as="a">Online Payment</Dropdown.Toggle>
                      <Dropdown.Menu as="ul">
                        <Dropdown.Item as="li">
                          <a
                            target="_blank"
                            href="https://api.shooliniuniversity.com/pay/"
                          >
                            {' '}
                            Pay Tuition Fee{' '}
                          </a>{' '}
                        </Dropdown.Item>
                        <Dropdown.Item as="li">
                          <a
                            target="_blank"
                            href="https://easypay.axisbank.co.in/easyPay/makePayment?mid=NDcwNjg%3D"
                          >
                            {' '}
                            Pay Hostel Fee{' '}
                          </a>{' '}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                  <li className="list-item">
                    <Dropdown>
                      <Dropdown.Toggle as="a">Ranking</Dropdown.Toggle>
                      <Dropdown.Menu as="ul">
                        <Dropdown.Item as="li">
                          <a
                            target="_blank"
                            href="https://shooliniuniversity.com/nba"
                          >
                            {' '}
                            NBA{' '}
                          </a>{' '}
                        </Dropdown.Item>
                        <Dropdown.Item as="li">
                          <a
                            target="_blank"
                            href="https://shooliniuniversity.com/naac-self-study-report"
                          >
                            {' '}
                            NAAC{' '}
                          </a>{' '}
                        </Dropdown.Item>
                        <Dropdown.Item as="li">
                          <a
                            target="_blank"
                            href="https://shooliniuniversity.com/nirf"
                          >
                            {' '}
                            NIRF{' '}
                          </a>{' '}
                        </Dropdown.Item>
                        <Dropdown.Item as="li">
                          <a
                            target="_blank"
                            href="https://shooliniuniversity.com/iqac"
                          >
                            {' '}
                            IQAC{' '}
                          </a>{' '}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <div
          className={`mb-sidebar-overlay ${visible ? ' visible' : ''}`}
          id="mb-sidebar-overlay"
          onClick={toggleMenu}
        ></div>
      </Styles>
    </>
  )
}

export default MobileMenu
