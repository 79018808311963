import './App.css'
//import ScrollToTop from './helper/ScrollToTop';
//import { GlobalStyle } from "./components/common/styles/global";
//import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import React   from 'react';
import Homepage from './Homepage'
import About from './Pages/About/About'
import Leadership from './Pages/Leadership/Leadership'
import VisionMission from './Pages/Vision-Mission/vision-mission'
import HistoryPage from './Pages/History/History'
import Undergraduate from './Pages/Programmes/Undergraduate/Undergraduate'
import Postgraduate from './Pages/Programmes/Postgraduate/Postgraduate'
import Doctoral from './Pages/Programmes/Doctoral/Doctoral'
import { Navigate, Route, Routes } from 'react-router-dom'
import Placements from './Pages/Placements/Placements'
import AdmissionProcess from './Pages/Admission/Admission-Process/AdmissionProcess'
import EducationSystem from './Pages/Admission/Education-System/EducationSystem'
import FeeStructure from './Pages/Admission/Fee-Structure/FeeStructure'
import Hostels from './Pages/Admission/Hostels/Hostels'
import Scholarships from './Pages/Admission/Scholarships/Scholarships'
import Infrastructure from './Pages/Campus/Infrastructure/Infrastructure'
import Sports from './Pages/Campus/Sports/Sports'
import CommunityProgrames from './Pages/Campus/Community-Programes/CommunityProgrames'
import ContactUs from './Pages/ContactUs/ContactUs'
import Result from './components/Result'
import ComingSoon from './components/ComingSoon'
import SchoolDetail from './Pages/School-Programe/SchoolDetail'
import ProgrameDetails from './Pages/Programmes/ProgrameDetails'
import ShooliniAdvantages from './Pages/Shoolini-Advantages/ShooliniAdvantages'
import ProgramSearched from './Pages/Programmes/ProgramSearched'
import ShooliniRanking from './Pages/Shoolini-Ranking/ShooliniRanking'
import Academics from './components/Academics'
import Faculty from './components/Faculty'
import FacultyProfile from './components/FacultyProfile'
import AcademicsSub from './components/Academics-sub'
import Internship from './Pages/Internship/Internship'
import TemplateOne from './Pages/SchoolTemplate/TemplateOne'
import GraduateAttributes from './Pages/GraduateAttributes/GraduateAttributes'
import Search from './components/common/Search'
import SearchResult from './components/SearchResult'
import GlobalAdvisoryBoard from './Pages/GlobalAdvisoryBoard/GlobalAdvisoryBoard'
import InternationalStudents from './Pages/International-Students/International-Students'
import NirfRanking from './Pages/Shoolini-Ranking/NirfRanking'
import Mba from './Landing-Pages/Mba/Mba'
import ShooliniImpactDetail from './components/ShooliniImpactDetail'
import DynamicTemplanting from './DynamicTemplanting'
import NotFound from './components/404'
import NewsTemplate from './Pages/News/NewsTemplate'
import NewsListTemplate from './Pages/News/NewsList'
// import ShooliniAdmission from './Pages/Admission/Admission-Process/CampusApiTemplate'
import ShooliniAdmission from './components/ShooliniAdmission';

import Brp from './Landing-Pages/Brps/Brp';
import Courses from './Pages/Courses/Courses';



function App() {
  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/about-us" element={<About />} />
      <Route path="/about-us" element={<About />} />
      <Route path="/courses" element={<Courses />} />

      <Route
        path="/shoolini-breaks-into-league-of-top-100-universities"
        element={<NirfRanking />}
      />
      <Route path="/vision-mission" element={<VisionMission />} />
      <Route path="/history" element={<HistoryPage />} />
      <Route path="/undergraduate-programs" element={<Undergraduate />} />
      <Route path="/postgraduate-programs" element={<Postgraduate />} />
      <Route path="/doctoral-programs" element={<Doctoral />} />
      <Route path="/placements" element={<Placements />} />
      <Route path="/leadership" element={<Leadership />} />
      <Route path="/graduate-attributes" element={<GraduateAttributes />} />
      <Route path="/global-advisory-board" element={<GlobalAdvisoryBoard />} />
      <Route path="/hostels" element={<AdmissionProcess id="88" />} />
      <Route
        path="/shoolini-admission"
        element={<ShooliniAdmission />}
      />
      //#region Faculty
      <Route
        path="/faculty-of-applied-sciences-biotechnology"
        element={<Faculty id="415" />}
      />
      <Route
        path="/faculty-of-yogananda-school-of-ai-computer-and-data-sciences"
        element={<Faculty id="417" />}
      />
      <Route path="/faculty-of-agriculture" element={<Faculty id="412" />} />
      <Route
        path="/faculty-of-management-sciences-liberal-arts"
        element={<Faculty id="413" />}
      />
      <Route
        path="/faculty-of-engineering-technology"
        element={<Faculty id="421" />}
      />
      <Route path="/faculty-of-basic-sciences" element={<Faculty id="420" />} />
      <Route
        path="/faculty-of-pharmaceutical-sciences"
        element={<Faculty id="414" />}
      />
      <Route path="/faculty-of-law" element={<Faculty id="418" />} />
      <Route
        path="/faculty-of-advanced-chemical-sciences"
        element={<Faculty id="426" />}
      />
      //#endregion Faculty
      <Route path="/contact" element={<ContactUs />} />
      <Route path="/results" element={<Result />} />
      <Route path="/coming-soon" element={<ComingSoon />} />
      <Route path="/shoolini-advantages" element={<ShooliniAdvantages />} />
      <Route path="/programe-searched" element={<ProgramSearched />} />
      {/* <Route path="/recognitions" element={<ShooliniRanking />} /> */}
      <Route path="/facultyprofile/:id" element={<FacultyProfile />} />
      <Route path="search" element={<SearchResult />} />
      <Route
        path="international-students"
        element={<InternationalStudents />}
      />
      <Route path="Internship" element={<Internship />} />
      <Route path="news" element={<NewsListTemplate />} />
      <Route path="news/:slug" element={<NewsTemplate />} />
      <Route path="/mba-landing" element={<Mba />} />


      {/* <Route path="/school-of-law" element={<SchoolDetail />} /> */}
      {/* <Route path="/schooldetail/:slug" element={<SchoolDetail />} /> */}
      {/* <Route path="/programedetail/:slug" element={<ProgrameDetails />} /> */}
      <Route path="/:slug" element={<DynamicTemplanting />} />
      <Route path="/404" element={<NotFound />} />
      <Route path="/brp" element={<Brp />} />



    </Routes>
  )
}

export default App
