import React from 'react'
import {Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./common/styles/shooliniAdvantage";
import { IoIosArrowDropright } from "react-icons/io";
import { Link } from 'react-router-dom';
const ShooliniAdvantage = () => {
    return (
        <>
             <Styles>
                 <section className="thinkLearning mt-5 pt-md-4">
                     <div className="container-fluid">
                         <Container>
                            <div data-aos="fade-right" className="sec-title mb-5">
                                <h2>Think Learning </h2>
                                <h4> at a world-class university </h4>
                            </div>
                         </Container>
                  

                         <Row>
                             <Col lg="7" md="4">
                                <div className="su_img">
                                    <img className="img-fluid" src="assets/images/su-learning.jpg" />
                                </div>
                             </Col>
                             <Col md="8" lg="5">
                                <div className="learningCard">
                                    <h3 className="subHeading"> The Shoolini Advantage  </h3>
                                    <div className="course-details-feature">
                                                <ul className="list-unstyled feature-list">
                                                    <li>  <Link to="/why-research-matters" > <span> Engage in Real Research</span> <span> <IoIosArrowDropright /> </span></Link></li>
                                                    <li>  <a href="https://api.shooliniuniversity.com/srp/" target="_blank" > <span> Re-Imagined Summit Research Program </span> <span> <IoIosArrowDropright /> </span></a></li>
                                                    <li><Link to="/placements" > <span>  Placements with Top Companies </span> <span>  <IoIosArrowDropright /></span></Link></li>
                                                    <li><Link to="/shoolini-university-key-faculty" > <span>Learn From the Best</span> <span> <IoIosArrowDropright /></span></Link></li>
                                                    <li><Link to="/shoolini-implements-nep-driven-curriculum" ><span>NEP-Driven Curriculum</span><span><IoIosArrowDropright /></span></Link></li>
                                             
                                                    
                                                    <li><a href="https://api.shooliniuniversity.com/VE-coaching/" target="_blank" > <span> Self-Discovery Through VE-V Empower </span> <span> <IoIosArrowDropright /></span></a></li>
                                                    <li><Link to="/coursera-learning-for-campus" > <span>  Coursera Offers World-Class Learning </span> <span> <IoIosArrowDropright /></span></Link></li>
                                                </ul>
                                               
                                                
                                               
                                            </div>
                                </div>
                             </Col>
                         </Row>
                     </div>
                 </section>
             </Styles>
        </>
    )
}

export default ShooliniAdvantage
